<template>
    <div class="policy">
        <div class="content-block">
            <Grid column="1" :gap="24">
                <div>
                    <router-link to="/policy">Back</router-link>
                </div>
                <div>
                    <p class="p2"><b>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</b></p>
                    <p class="p2"><b>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</b></p>
                    <p class="p2">В тексте настоящего Соглашения нижеизложенным терминам дано следующее значение:</p>
                    <p class="p2">"<b>Компания</b>", "<b>Мы</b>", "<b>Наш</b>", "<b>Нас</b>", "<b>Нами</b>" или какие-либо
                        другие схожие производные (в зависимости от контекста)</p>
                    <p class="p2">означает следующее лицо:<b> BAKU PRODUKSIYON KONGRE ORGANIZASYON OZEL EGITIM DANISMANLIGI
                            VE</b></p>
                    <p class="p2"><b>TURIZM TIC LTD STI</b>, адрес местонахождения: Сaglayan, 2054. Sk. 29/2, 07230
                        Муратпаша/Анталия, Турция, которые</p>
                    <p class="p2">являются владельцами Приложения или им управляют.</p>
                    <p class="p2"><b>«Партнер» </b>- означает следующее юридическое лицо: ООО "Траектория", адрес
                        местонахождения: Россия, г. Москва, ул.</p>
                    <p class="p2">2ая Рощинская, дом 10, помещение 113, связанное договорными обязательствами с Компанией.
                    </p>
                    <p class="p2">"<b>Контент приложения</b>" означает все объекты, размещенные Компанией и/или третьими
                        лицами (с разрешения Компании)</p>
                    <p class="p2">в Приложении, в том числе элементы дизайна, текст, графические изображения, иллюстрации,
                        виртуальные объекты,</p>
                    <p class="p2">видео, программы, музыка, звуки, информация, уведомления и какие-либо другие схожие
                        объекты, их подборки или</p>
                    <p class="p2">комбинации.</p>
                    <p class="p2">"<b>Обновления</b>" означает программный патч или программный пакет для Приложения,
                        который время от времени</p>
                    <p class="p2">выпускается Компанией, предлагается для бесплатной загрузки Пользователями, которые уже
                        используют Приложение, и</p>
                    <p class="p2">направлен на фиксацию неработающих функций Приложения, устранение багов (ошибок) в работе
                        Приложения или</p>
                    <p class="p2">внедрение небольших программных компонентов для обеспечения большей безопасности и
                        совместимости Приложения с</p>
                    <p class="p2">устройствами.</p>
                    <p class="p2">"<b>Платформа</b>" означает облачную платформу, на которой Компания разместила Приложение
                        для его последующего</p>
                    <p class="p2">скачивания Пользователем. Настоящее Приложение доступно для скачивания из App Store и
                        Google Play.</p>
                    <p class="p2">"<b>ПО приложения</b>" означает программное обеспечение, разработанное Компанией (и/или
                        третьими лицами по поручению</p>
                    <p class="p2">Компании) для Приложения, включая, однако не ограничиваясь, весь софт, скрипты, коды (HTML
                        коды), программы и тп.</p>
                    <p class="p2">"<b>Пользователь</b>", "<b>Вы</b>", "<b>Ваш</b>", "<b>Вас</b>", "<b>Вами</b>" или
                        какие-либо другие схожие производные (в зависимости от контекста)</p>
                    <p class="p2">означает лицо, которое <b>(1)</b> пользуется Приложением и получило доступ к Услугам; и
                        <b>(2)</b> дало свое согласие на соблюдение</p>
                    <p class="p2">правил пользования Приложением, изложенных в тексте данного Соглашения, путем создания
                        личного аккаунта</p>
                    <p class="p2">(кабинета).</p>
                    <p class="p2">"<b>Приложение</b>" означает следующее приложение: Excursio, которое Пользователь
                        загружает через Платформу на смартфон</p>
                    <p class="p2">или другое устройство.</p>
                    <p class="p2">"<b>Услуги</b>" означает совокупно Контент приложения и ПО приложения.</p>
                    <p class="p2">"<b>In-app покупка</b>" означает получение Пользователем за плату дополнительных
                        возможностей и/или функционала для</p>
                    <p class="p2">Приложения и/или приобретение каких-либо виртуальных товаров/услуг в рамках Приложения.
                        Пользователю доступны</p>
                    <p class="p2">следующие виртуальные товары/услуги/продукты: Экскурсионные услуги на территории Турции.
                    </p>
                    <p class="p2"><b>2. ПРИСОЕДИНЕНИЕ К СОГЛАШЕНИЮ</b></p>
                    <p class="p2"><b>Общие положения</b></p>
                    <p class="p2">2.1. Настоящее Пользовательское соглашение (далее "<b>Соглашение</b>") определяет правила
                        и порядок использования</p>
                    <p class="p2">Приложения, права и обязанности Пользователей, а также регулирует поведение Пользователей
                        при получении доступа к</p>
                    <p class="p2">Приложению и Услугам.</p>
                    <p class="p2">2.2. Пользователь принимает условия данного Соглашения путем создания личного аккаунта
                        (авторизации в приложении).</p>
                    <p class="p2">2.3. Данное Соглашение является обязательным для его сторон (т.е. для Компании и
                        Пользователя). Уступка</p>
                    <p class="p2">Пользователем своих прав по данному Соглашению возможна только после получения
                        предварительного письменного</p>
                    <p class="p2">согласия от Компании.</p>
                    <p class="p2">2.4. Если Вы являетесь законным представителем (родителем, опекуном) несовершеннолетнего
                        Пользователя, в таком</p>
                    <p class="p2">случае Вы автоматически соглашаетесь от своего имени и от имени такого несовершеннолетнего
                        Пользователя на условия</p>
                    <p class="p2">данного Соглашения.<span class="s1"><i>2/7</i></span></p>
                    <p class="p2"><b>Предупреждение для пользователей</b></p>
                    <p class="p2">2.5. Если Пользователь осуществляет доступ к Приложению и/или его Услугам через Платформу
                        или социальные сети (к</p>
                    <p class="p2">примеру, Facebook, Vkontakte, Google, Apple), в таком случае Пользователь автоматически
                        считается таким, который</p>
                    <p class="p2">принял условия пользовательского соглашения такой Платформы или социальной сети.</p>
                    <p class="p2">2.6. Пользователь признает, что его соглашение с провайдером мобильной сети (далее
                        "<b>Провайдер</b>") будет применяться к</p>
                    <p class="p2">использованию Пользователем данного Приложения. Пользователь также признает, что Провайдер
                        может взимать с</p>
                    <p class="p2">Пользователя время от времени плату за услуги передачи данных при использовании отдельных
                        функций Приложения, а</p>
                    <p class="p2">также какие-либо иные сборы и платежи, возникающие в связи с такой передачей и за которые
                        Пользователь обязуется</p>
                    <p class="p2">нести ответственность. Если Пользователь не является плательщиком счетов Провайдера на
                        смартфоне или другом</p>
                    <p class="p2">устройстве, используемом для доступа в Приложение, предполагается, что такой Пользователь
                        получил разрешение от</p>
                    <p class="p2">плательщика счета на использование Приложения.</p>
                    <p class="p2">2.7. Пользователь является единственным ответственным лицом за проверку и контроль
                        соответствия устанавливаемого</p>
                    <p class="p2">Приложения техническим особенностям/возможностям смартфона или иного устройства и/или
                        других ограничений,</p>
                    <p class="p2">которые могут быть применимы к Пользователю и/или его смартфону или другому устройству
                        третьими лицами, включая</p>
                    <p class="p2">Интернет провайдера.</p>
                    <p class="p2"><b>3. ПОЛЬЗОВАТЕЛИ ПРИЛОЖЕНИЯ</b></p>
                    <p class="p2"><b>Общие критерии и возраст</b></p>
                    <p class="p2">3.1. Для использования Приложения Пользователи должны соответствовать следующим критериям
                        (совокупно):</p>
                    <p class="p2"><b>(1)</b> быть не младше 18-лет; и</p>
                    <p class="p2"><b>(2)</b> не быть ограниченными в праве доступа к Приложению и Услугам на основании
                        решения суда, вступившего в</p>
                    <p class="p2">законную силу, или в случаях, предусмотренных действующим законодательством или условиями
                        данного</p>
                    <p class="p2">Соглашения.</p>
                    <p class="p2"><b>Создание личного аккаунта</b></p>
                    <p class="p2">3.2. Для получения доступа к Услугам Пользователям требуется создать свою учетную запись.
                        Пользование Услугами без</p>
                    <p class="p2">регистрации (т.е. создания учетной записи (профиля) невозможно).</p>
                    <p class="p2">3.3. Для создания личного аккаунта Пользователю необходимо предоставить следующею
                        информацию о себе:</p>
                    <p class="p2"><i>Фамилию, Имя, номер мобильного телефона или адрес электронной почты</i></p>
                    <p class="p2"><b>4. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</b></p>
                    <p class="p2"><b>Пользовательская лицензия</b></p>
                    <p class="p2">4.1. Пользователь получает неисключительную, без права передачи и сублицензии, ТОЛЬКО для
                        личного</p>
                    <p class="p2">(некоммерческого) пользования лицензию на Услуги (далее "<b>Пользовательская
                            лицензия</b>"). Пользователь берет на себя</p>
                    <p class="p2">обязательство не использовать Услуги в каких-либо других целях. Пользователь получает
                        указанную Пользовательскую</p>
                    <p class="p2">лицензию ТОЛЬКО при условии соблюдения ВСЕХ условий данного Соглашения.</p>
                    <p class="p2">4.2. Пользовательская лицензия прекращает действовать автоматически при удалении
                        Приложения со смартфона или</p>
                    <p class="p2">другого устройства Пользователя. Ничто в тексте настоящего Соглашения не должно быть
                        истолковано как право на</p>
                    <p class="p2">получение Пользователем какой-либо иной лицензии на использование объектов
                        интеллектуальной собственности,</p>
                    <p class="p2">принадлежащих Компании или находящихся в ее владении, кроме той, которая предоставлена
                        выше.</p>
                    <p class="p2"><b>Интеллектуальная собственность компании</b></p>
                    <p class="p2">4.3. Компании принадлежат все без исключения права собственности, включая имущественные
                        права интеллектуальной</p>
                    <p class="p2">собственности, на весь Контент приложения, а также ПО приложения. ПО приложения и Контент
                        приложения защищены<span class="s1"><i>3/7</i></span></p>
                    <p class="p2">авторским правом в порядке, предусмотренном действующим гражданским законодательством
                        Российской Федерации, а</p>
                    <p class="p2">также международными договорами и конвенциями в области защиты объектов интеллектуальной
                        собственности.</p>
                    <p class="p2">4.4. ПОЛЬЗОВАТЕЛЯМ ЗАПРЕЩЕНО копировать, воспроизводить, модифицировать, компилировать,
                        распространять,</p>
                    <p class="p2">отображать в какой-либо форме, публиковать, скачивать, передавать, продавать (полностью
                        или частично), отчуждать</p>
                    <p class="p2">любым возможным способом за плату или безвозмездно, передавать в сублицензию, каким-либо
                        способом распространять</p>
                    <p class="p2">или использовать Контент приложения и ПО приложения, кроме случаев, когда такие действия
                        ПРЯМО разрешены</p>
                    <p class="p2">условиями данного Соглашения или действующим законодательством Российской Федерации.</p>
                    <p class="p2">4.5. Ничто в тексте данного Соглашения не может быть истолковано как передача Пользователю
                        каких-либо</p>
                    <p class="p2">исключительных прав на Контент приложения (полностью или в отдельной части) и/или ПО
                        приложения.</p>
                    <p class="p2">4.6. Компания владеет всеми правами в отношении торговых марок, коммерческих (бизнес)
                        наименований, брендов,</p>
                    <p class="p2">логотипов, зарегистрированных на ее имя (далее "<b>Торговые марки</b>"). Такие Торговые
                        марки защищаются действующим</p>
                    <p class="p2">законодательством и НИЧТО в тексте данного Соглашения не может быть истолковано как
                        передача какой-либо лицензии</p>
                    <p class="p2">Пользователю на использование таких Торговых марок.</p>
                    <p class="p2"><b>5. ПОРЯДОК РАБОТЫ С ПРИЛОЖЕНИЕМ</b></p>
                    <p class="p2"><b>Общие положения</b></p>
                    <p class="p2">5.1. Пользователь обязуется соблюдать следующие правила при работе с Приложением:</p>
                    <p class="p2"><b>(1)</b> соблюдать все обязательства, взятые на себя Пользователем в связи с
                        присоединением к данному Соглашению;</p>
                    <p class="p2"><b>(2)</b> предоставлять достоверные о себе данные для создания личного аккаунта
                        (кабинета);</p>
                    <p class="p2"><b>(3)</b> не выдавать себя за какое-либо другое лицо, включая, однако не ограничиваясь,
                        не предоставлять какие-либо</p>
                    <p class="p2">данные третьих лиц (без получения от них прямого, предварительного и осознанного согласия)
                        для создания личного</p>
                    <p class="p2">аккаунта (кабинета);</p>
                    <p class="p2"><b>(4)</b> информировать Компанию о хищении данных или каких-либо других ключей доступа
                        Пользователя к личному</p>
                    <p class="p2">аккаунту (кабинету);</p>
                    <p class="p2"><b>(5)</b> не предоставлять третьим лицам доступ к своему аккаунту (кабинету) или другие
                        ключи доступа;</p>
                    <p class="p2"><b>(6)</b> не совершать какие-либо действия (с использованием средств автоматизации или
                        без таких средств),</p>
                    <p class="p2">направленные на сбор каких-либо персональных данных других Пользователей;</p>
                    <p class="p2"><b>(7)</b> не предпринимать какие-либо действия и не помогать третьим лицам в совершении
                        действий, направленных на</p>
                    <p class="p2">подрыв работы Приложения и/или Услуг, включая, однако не ограничиваясь<b>, (а)</b>
                        загружать вирусы или вредоносный</p>
                    <p class="p2">код; <b>(б)</b> совершать действия, которые могут привести к отключению Приложения и/или
                        Услуг, к нарушению</p>
                    <p class="p2">нормальной работы Приложения или его программного обеспечения, или к ухудшению внешнего
                        вида Приложения</p>
                    <p class="p2">и/или Контента приложения.</p>
                    <p class="p2"><b>(8)</b> не предпринимать какие-либо иные действия, которые являются незаконными,
                        мошенническими,</p>
                    <p class="p2">дискриминационными или вводящими в заблуждение.</p>
                    <p class="p2"><b>Отзывы о работе приложения</b></p>
                    <p class="p2">5.2. Каждый Пользователь время от времени имеет право (но не обязанность) оставлять или
                        направлять свои идеи, отзывы,</p>
                    <p class="p2">предложения или проекты, направленные на улучшение работы Приложения или качества
                        предоставляемых Услуг. Такие</p>
                    <p class="p2">отзывы могут быть направлены Пользователем следующим способом: info@excursio.ru</p>
                    <p class="p2">5.3. В случае направления такой идеи, отзыва, предложения или проекта Пользователь
                        автоматически предоставляет нам</p>
                    <p class="p2">неисключительную, не требующую лицензионных отчислений, действующую по всему миру лицензию
                        с правом передачи</p>
                    <p class="p2">и выдачи сублицензий на хранение, использование, распространение, изменение, запуск,
                        копирование, публичное</p>
                    <p class="p2">исполнение или показ, перевод Ваших идей, отзывов, предложений или проектов, а также
                        создание производных работ на</p>
                    <p class="p2">их основе.</p>
                    <p class="p2">5.4. Любая такая информация, предоставляемая Компании, автоматически признается
                        неконфиденциальной.<span class="s1"><i>4/7</i></span></p>
                    <p class="p2"><b>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ В ПРИЛОЖЕНИИ</b></p>
                    <p class="p2"><b>Размещение рекламы компанией</b></p>
                    <p class="p2">6.1. Компания имеет право время от времени размещать какие-либо рекламные или
                        маркетинговые материалы.</p>
                    <p class="p2"><b>Размещение рекламы третьими лицами</b></p>
                    <p class="p2">6.2. Контент приложения может содержать ссылки на веб-сайты третьих лиц и/или рекламные
                        или маркетинговые</p>
                    <p class="p2">материалы о товарах/услугах, предоставляемых такими третьими лицами (далее "<b>Реклама
                            третьих лиц</b>"). КОМПАНИЯ</p>
                    <p class="p2">НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО ОТВЕТСТВЕННОСТЬ <b>(1)</b> ЗА СОДЕРЖАНИЕ РЕКЛАМЫ ТРЕТЬИХ ЛИЦ,
                        А</p>
                    <p class="p2">ТАКЖЕ ЗА НАЛИЧИЕ, КАЧЕСТВО И БЕЗОПАСНОСТЬ ПРОДВИГАЕМЫХ ТОВАРОВ/УСЛУГ В ТАКОЙ РЕКЛАМЕ;</p>
                    <p class="p2">и <b>(2)</b> ЗА КАКИЕ-ЛИБО УБЫТКИ, ПОТЕРИ ИЛИ УЩЕРБ, ПОНЕСЕННЫЕ ИЛИ ПРИЧИНЕННЫЕ
                        ПОЛЬЗОВАТЕЛЮ В</p>
                    <p class="p2">РЕЗУЛЬТАТЕ ПРОЧТЕНИЯ ИМ ТАКОЙ РЕКЛАМЫ, ИСПОЛЬЗОВАНИЯ ИМ ПРОДВИГАЕМЫХ В РЕКЛАМЕ</p>
                    <p class="p2">ТРЕТЬИХ ЛИЦ ТОВАРОВ/УСЛУГ.</p>
                    <p class="p2">6.3. В случае перехода на другой сайт через размещенную Рекламу третьих лиц, Компания не
                        может гарантировать, что</p>
                    <p class="p2">такой веб-сайт является безопасным для Пользователя и/или его компьютера. Ни что в тексте
                        данного Соглашения не</p>
                    <p class="p2">должно быть истолковано как заверение, поощрение, рекомендация или побуждение Пользователя
                        воспользоваться</p>
                    <p class="p2">Рекламой третьих лиц, посетить какие-либо сайты третьих лиц, а также попробовать,
                        приобрести, воспользоваться какими-</p>
                    <p class="p2">либо товарами/услугами третьих лиц.</p>
                    <p class="p2">6.4. Вопросы, связанные с защитой персональных данных Пользователей при использовании ими
                        Рекламы третьих лиц,</p>
                    <p class="p2">регулируются Политикой обработки персональных данных.</p>
                    <p class="p2"><b>7. ПРОВЕДЕНИЕ ОПЛАТЫ ЧЕРЕЗ ПРИЛОЖЕНИЕ</b></p>
                    <p class="p2"><b>Общие положения</b></p>
                    <p class="p2">7.1. Пользователи имеют возможность проводить оплату за товары/услуги через Приложение.
                        Продавцом таких</p>
                    <p class="p2">товаров/услуг может выступать как сама Компания, так и третьи лица (партнеры, продавцы,
                        дистрибьюторы) (далее</p>
                    <p class="p2">"<b>Поставщики</b>") - в зависимости от того, что указано в описании к тому или иному
                        товару/услуге.</p>
                    <p class="p2">7.2. ПРЕДУПРЕЖДЕНИЕ ПРИ ПОКУПКЕ ТОВАРОВ/УСЛУГ ОТ ПОСТАВЩИКОВ. Ответственность за
                        исполнение</p>
                    <p class="p2">договора купли-продажи, заключенного между Пользователем и Поставщиком через Приложение на
                        основании</p>
                    <p class="p2">предоставленной Компанией информации о товаре/услуге или Поставщике, а также за соблюдение
                        прав потребителей,</p>
                    <p class="p2">нарушенных в результате передачи потребителю товара/услуги ненадлежащего качества и обмена
                        непродовольственного</p>
                    <p class="p2">товара надлежащего качества на аналогичный товар, несет Поставщик.</p>
                    <p class="p2">7.3. Компания не хранит какие-либо данные о Вашей дебетовой или кредитной карте,
                        используемой для оплаты.</p>
                    <p class="p2">7.4. КОМПАНИЯ ПРЕДОСТАВЛЯЕТ ЗАКАЗАННЫЙ ТОВАР/УСЛУГУ ТОЛЬКО ПОСЛЕ ПОЛУЧЕНИЯ ОПЛАТЫ В</p>
                    <p class="p2">ПОЛНОМ ОБЪЕМЕ.</p>
                    <p class="p2">7.5. Если Компания не может предоставить Вам заказанный и оплаченный товар/услугу,
                        Компания обязуется</p>
                    <p class="p2">незамедлительно провести возврат Вам всей уплаченной ранее суммы в счет погашения
                        стоимости заказанного</p>
                    <p class="p2">товара/услуги (за исключением банковских комиссий и издержек, которые могли быть начислены
                        Вашим обслуживающим</p>
                    <p class="p2">банком при оплате товаров/услуг через Приложение).</p>
                    <p class="p2">7.6. Компания не гарантирует наличие того или иного товара/услуги.</p>
                    <p class="p2"><b>Порядок оплаты</b></p>
                    <p class="p2">7.7. Стоимость товара/услуги в Приложении указывается в долларах США. Оплата за
                        товар/услугу также должна быть</p>
                    <p class="p2">проведена в российских рублях. Конвертация происходит по курсу ЦБ РФ на день совершения
                        покупки + 2%.</p>
                    <p class="p2">7.8. Если у Вас нет счета в указанной валюте, в таком случае Вы можете воспользоваться
                        Вашей дебетовой или кредитной</p>
                    <p class="p2">картой, а обслуживающий банк (держатель дебетовой или кредитной карты) проведет
                        соответствующею конвертацию по</p>
                    <p class="p2">обменному курсу согласно его внутрибанковским правилам. Компания не отвечает за обменный
                        курс, используемый</p>
                    <p class="p2">Вашим обслуживающим банком при такой конвертации.</p>
                    <p class="p2">7.9. Пользователь может провести оплату одним из следующих способов:<span
                            class="s1"><i>5/7</i></span></p>
                    <p class="p2"><i>(1) банковской картой</i></p>
                    <p class="p2">7.10. Обращаем внимание, что Компания может в любой момент отказать в приеме того или
                        иного способа платежа без</p>
                    <p class="p2">какого-либо объяснения или уведомления Пользователей.</p>
                    <p class="p2">7.11. Товар/услуга считается оплаченным Пользователем в полном объеме с момента
                        подтверждения исполнения такого</p>
                    <p class="p2">платежа банковским учреждением, обслуживающим дебетовую или кредитную карту Пользователя,
                        которая была им</p>
                    <p class="p2">использована для оплаты через Приложение.</p>
                    <p class="p2">7.12. Обращаем внимание, что Ваш обслуживающий банк может проводить по своему собственному
                        усмотрению</p>
                    <p class="p2">дополнительные проверки совершенной транзакции, что в последствие может привести к
                        задержке оплаты заказанного</p>
                    <p class="p2">Вами ранее товара/услуги.</p>
                    <p class="p2">7.13. Компания не берет на себя какую-либо ответственность за убытки, ущерб, упущенную
                        выгоду, потерю деловой</p>
                    <p class="p2">репутации, возникшие у Пользователя из-за задержки в оплате, что в свою очередь могло
                        привести к задержке с нашей</p>
                    <p class="p2">стороны в предоставлении Вам определенного товара/услуги.</p>
                    <p class="p2"><b>Акции на товары/услуги</b></p>
                    <p class="p2">7.14. Время от времени Компания может размещать какие-либо акционные предложения на
                        товары/услуги. Периодичность</p>
                    <p class="p2">размещения таких предложений, а также их условия определяются исключительно Компанией.</p>
                    <p class="p2">7.15. Пользователь осведомлен, что количество акционных предложений на товары/услуги
                        ограничено.</p>
                    <p class="p2">7.16. Компания не гарантирует и не обещает Пользователям, что <b>(1)</b> приобретение
                        каких-либо акционных товаров/услуг</p>
                    <p class="p2">является каким-либо образом выгодным для Пользователя и/или третьих лиц; и <b>(2)</b>
                        стоимость акционных товаров/услуг</p>
                    <p class="p2">должна быть в обязательном порядке ниже их обычной стоимости, указанной в Приложении, или
                        на других веб-сайтах</p>
                    <p class="p2">третьих лиц.</p>
                    <p class="p2"><b>8. ДОСТУП К ПРИЛОЖЕНИЮ</b></p>
                    <p class="p2"><b>Общие положения</b></p>
                    <p class="p2">8.1. Компания оставляет за собой право изменить или модифицировать Контент приложения в
                        любой момент без указания</p>
                    <p class="p2">причины, по своему собственному усмотрению и без необходимости уведомления об этом
                        Пользователя. Компания также</p>
                    <p class="p2">оставляет за собой право модифицировать, прервать или прекратить работу части или всего
                        Приложения в любой момент</p>
                    <p class="p2">без какого-либо дополнительного уведомления. В связи с изложенным Компания не берет на
                        себя какую-либо</p>
                    <p class="p2">ответственность перед Пользователями или третьими лицами за любые произведенные изменения,
                        модификации,</p>
                    <p class="p2">удаления, упразднения, прекращения или перерывы в работе Приложения.</p>
                    <p class="p2">8.2. Компания не гарантирует, что Приложение и Услуги будут доступными Пользователю
                        постоянно. Время от времени</p>
                    <p class="p2">Компания может сталкиваться с проблемами в работе оборудования, ПО приложения или другими
                        неполадками, в связи с</p>
                    <p class="p2">чем Компании может потребоваться время на изучение и устранение таких проблем. Такое
                        устранение ошибок может</p>
                    <p class="p2">привести к сбоям, задержкам или ошибкам в работе Приложения. Компания оставляет за собой
                        право изменять,</p>
                    <p class="p2">пересматривать, обновлять, приостанавливать, прекращать или иным образом изменять
                        Приложение в любое время или</p>
                    <p class="p2">по любой причине без предварительного уведомления. Пользователь соглашается с тем, что
                        Компания не несет</p>
                    <p class="p2">ответственности за какие-либо убытки, ущерб или неудобства, вызванные неспособностью
                        Пользователя получить доступ</p>
                    <p class="p2">или использовать Приложение во время простоя или прекращения работы Приложения. Ничто в
                        условиях данного</p>
                    <p class="p2">Соглашения не будет истолковано как обязывающее Нас поддерживать работу Приложения без
                        остановок и сбоев.</p>
                    <p class="p2"><b>Предоставление обновлений</b></p>
                    <p class="p2">8.3. Компания время от времени может предоставлять Обновления и требовать их инсталляции
                        на смартфон или другое</p>
                    <p class="p2">устройство Пользователя. В данном случае Пользователь является единственным ответственным
                        лицом за установление</p>
                    <p class="p2">Обновлений и несет полную ответственность за какие-либо убытки, потери, ущерб или
                    упущенную выгоду, причиненную</p>
                <p class="p2">Пользователю несвоевременным установлением Обновлений или неустановлением их вовсе,
                    несовместимостью</p>
                <p class="p2">установленных Обновлений и смартфона/другого устройства. Компания не предоставляет
                    какую-либо техническую</p>
                <p class="p2">поддержку или интернет соединение Пользователю для возможности получения доступа к Услугам
                    и/или их Обновлениям.</p>
                <p class="p2"><b>Удаление аккаунта</b></p>
                <p class="p2">8.4. Пользователь имеет право в любой момент прекратить использование Приложения
                    посредством удаления его со своего</p>
                <p class="p2">смартфона или другого устройства.<span class="s1"><i>6/7</i></span></p>
                <p class="p2">8.5. В случае <b>(1)</b> нарушения Пользователем условий настоящего Соглашения или когда у
                    Компании есть достаточные</p>
                <p class="p2">основания полагать, что такие нарушения были допущены; и/или <b>(2)</b> нарушения прав
                    интеллектуальной собственности</p>
                <p class="p2">Компании, других Пользователей или третьих лиц; и/или <b>(3)</b> совершения действий,
                    которые являются противозаконными,</p>
                <p class="p2">нарушают права и интересы Компании, других Пользователей или третьих лиц или подрывают
                    работу Приложения или</p>
                <p class="p2">возможность использования Приложения другими Пользователями; и/или <b>(4)</b> Пользователь
                    использует Услуги или</p>
                <p class="p2">Приложение таким образом, что это может повлечь юридическую ответственность для Компании в
                    будущем; и/или <b>(5)</b></p>
                <p class="p2">если этого требует действующее законодательство или компетентный государственный орган,
                    Компания имеет право без</p>
                <p class="p2">предварительного уведомления в любой момент прекратить (остановить) доступ Пользователя к
                    Приложению и Услугам</p>
                <p class="p2">посредством удаления его аккаунта.</p>
                <p class="p2">При наступлении обстоятельств, изложенных в предыдущем абзаце, Пользователю запрещено
                    создавать какие-либо другие</p>
                <p class="p2">аккаунты в Приложении в будущем.</p>
                <p class="p2">8.6. Во всех случаях удаления аккаунта Пользователя или удаления Приложения со смартфона
                    или другого устройства</p>
                <p class="p2">Пользователя все данные и информация, размещенные Пользователем в аккаунте и/или связанные
                    с ним, будут</p>
                <p class="p2">безвозвратно удалены. Компания не берет на себя какую-либо ответственность за удаление
                    таких данных и информации,</p>
                <p class="p2">а также за какой-либо вред, ущерб, убытки или упущенную выгоду, причиненную Пользователю
                    таким удалением и/или</p>
                <p class="p2">отсутствием доступа к Услугам в целом.</p>
                <p class="p2"><b>9. ЗАДАТЬ ВОПРОС</b></p>
                <p class="p2">9.1. Если у Вас есть вопросы касательно условий настоящего Соглашения или порядка/способа
                    их исполнения, Вы можете</p>
                <p class="p2">адресовать нам свой вопрос следующим способом:</p>
                <p class="p2"><i>Отправить запрос на указанный в разделе Контакты в Приложении адрес электронной
                        почты</i></p>
                <p class="p2"><i>Отправить запрос на указанный в разделе Контакты в Приложении контакт в Телеграм</i>
                </p>
                <p class="p2"><i>Позвонить на указанный в разделе Контакты в Приложении телефон</i></p>
                <p class="p2">9.2. Сотрудники и представители Компании обязуются предпринять все возможные усилия для
                    дачи ответа на Ваш запрос</p>
                <p class="p2">в течение разумного периода времени.</p>
                <p class="p2"><b>10. ОТВЕТСТВЕННОСТЬ</b></p>
                <p class="p2">10.1. НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ КОМПАНИЯ ИЛИ ЕЕ ПРЕДСТАВИТЕЛИ НЕ НЕСУТ</p>
                <p class="p2">ОТВЕТСТВЕННОСТЬ ПЕРЕД ПОЛЬЗОВАТЕЛЕМ ИЛИ ПЕРЕД ЛЮБЫМИ ТРЕТЬИМИ ЛИЦАМИ:</p>
                <p class="p2"><b>(1)</b> ЗА ЛЮБОЙ КОСВЕННЫЙ, СЛУЧАЙНЫЙ, НЕУМЫШЛЕННЫЙ УЩЕРБ, ВКЛЮЧАЯ УПУЩЕННУЮ</p>
                <p class="p2">ВЫГОДУ ИЛИ ПОТЕРЯННЫЕ ДАННЫЕ, ВРЕД ЧЕСТИ, ДОСТОИНСТВУ ИЛИ ДЕЛОВОЙ РЕПУТАЦИИ,</p>
                <p class="p2">ВЫЗВАННЫЙ ИСПОЛЬЗОВАНИЕМ ПРИЛОЖЕНИЯ, УСЛУГ ИЛИ ИНЫХ МАТЕРИАЛОВ, К КОТОРЫМ</p>
                <p class="p2">ПОЛЬЗОВАТЕЛЬ ИЛИ ИНЫЕ ЛИЦА ПОЛУЧИЛИ ДОСТУП С ПОМОЩЬЮ ПРИЛОЖЕНИЯ, ДАЖЕ ЕСЛИ</p>
                <p class="p2">КОМПАНИЯ ПРЕДУПРЕЖДАЛА ИЛИ УКАЗЫВАЛА НА ВОЗМОЖНОСТЬ ТАКОГО ВРЕДА; и</p>
                <p class="p2"><b>(2)</b> ЗА ДЕЙСТВИЯ ДРУГИХ ПОЛЬЗОВАТЕЛЕЙ, ЗА РАЗМЕЩАЕМЫЙ ПОЛЬЗОВАТЕЛЯМИ</p>
                <p class="p2">ПОЛЬЗОВАТЕЛЬСКИЙ КОНТЕНТ, ЗА ТОВАРЫ/УСЛУГИ, ПРЕДОСТАВЛЯЕМЫЕ ТРЕТЬИМИ ЛИЦАМИ ИЛИ</p>
                <p class="p2">ДРУГИМИ ПОЛЬЗОВАТЕЛЯМИ (ДАЖЕ ЕСЛИ ДОСТУП К НИМ БЫЛ ПРЕДОСТАВЛЕН ЧЕРЕЗ НАШЕ</p>
                <p class="p2">ПРИЛОЖЕНИЕ), К КОТОРЫМ ПОЛЬЗОВАТЕЛЬ ПОЛУЧИЛ ДОСТУП; и</p>
                <p class="p2"><b>(3)</b> в случаях прямо предусмотренных условиями настоящего Соглашения или нормой
                    действующего</p>
                <p class="p2">законодательства.</p>
                <p class="p2">10.2. Ответственность Компании за все что угодно, связанное с использованием Приложения
                    и/или Услуг, ограничивается</p>
                <p class="p2">настолько, насколько это допускается действующим законодательством.</p>
                <p class="p2"><b>11. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</b></p>
                <p class="p2">11.1. В случае возникновения любых споров или разногласий, связанных с исполнением
                    настоящего Соглашения,</p>
                <p class="p2">Пользователь и Компания приложат все усилия для их разрешения путем проведения переговоров
                    между ними. В случае,<span class="s1"><i>7/7</i></span></p>
                <p class="p2">если споры не будут разрешены путем переговоров, споры подлежат разрешению в порядке,
                    установленном действующим</p>
                <p class="p2">законодательством Российской Федерации.</p>
                <p class="p2"><b>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b></p>
                <p class="p2">12.1. Мы можем время от времени пересматривать, дополнять или изменять условия данного
                    Соглашения. Такие</p>
                <p class="p2">изменения, как правило, не являются ретроспективными.</p>
                <p class="p2">КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО ОБЯЗАННОСТЬ ПО УВЕДОМЛЕНИЮ ПОЛЬЗОВАТЕЛЕЙ О</p>
                <p class="p2">ПРЕДСТОЯЩИХ ИЛИ СОСТОЯВШИХСЯ ИЗМЕНЕНИЯХ ТЕКСТА СОГЛАШЕНИЯ. Посредством присоединения к</p>
                <p class="p2">условиям данного Соглашения Пользователь также берет на себя обязательство периодически
                    просматривать условия</p>
                <p class="p2">данного Соглашения на предмет их изменения или дополнения.</p>
                <p class="p2">Если после проведенных изменений или дополнений в тексте Соглашения Пользователь
                    продолжает пользоваться</p>
                <p class="p2">Приложением, это значит, что он ознакомлен с изменениями или дополнениями и их принял в
                    полном объеме без каких-</p>
                <p class="p2">либо возражений.</p>
                <p class="p2">12.2. Если иное прямо не указано в положениях настоящего Соглашения или прямо не вытекает
                    из норм действующего</p>
                <p class="p2">законодательства, к условиям настоящего Договора применяется материальное право Российской
                    Федерации.</p>
                <p class="p2">12.3. Неотъемлемой частью настоящего Соглашение является Политика конфиденциальности.</p>
                <p class="p2">12.4. Если одно или несколько условий данного Соглашения утратило свою юридическую силу
                    или признано</p>
                <p class="p2">недействительным согласно действующему законодательству, остальные условия Соглашения не
                    теряют своей силы и</p>
                <p class="p2">продолжают действовать так, словно признанного недействительным или утратившим юридическую
                    силу условия не</p>
                <p class="p2">существовало вовсе.</p>
                <p class="p2">12.5. Доступ к Приложению и его Услугам предоставляется Пользователю "как есть", Мы не
                    обещаем, не гарантируем, не</p>
                <p class="p2">подразумеваем, что Услуги и Приложение могут подходить или не подходить Вашим
                    потребностям, целям, ожиданиям, и</p>
                <p class="p2">в связи с чем не гарантируем какого-либо специфического результата или последствия в
                    результате использования Вами</p>
                <p class="p2">Приложения и его Услуг.</p>
            </div>
        </Grid>
    </div>
</div></template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'LoginPage',
    // components: {
    //     vueTelInput
    // },
    mixins: [globalMethods],
    data() {
        return {
            defaultCountry: 'ru',
            login: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
            },
            password: '',
            remember: false,
            value: String,
            dialog: false
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
    },
    watch: {

    },
    methods: {
    }
}
</script>
<style lang="scss">.policy {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10px;
    gap: 20px;
    width: 100%;
    min-height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .content-block {
        padding: 22px 49px;
        background: #fff;
    }
}</style>

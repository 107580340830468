<template>
    <div class="policy">
        <div class="content-block">
            <Grid column="1" :gap="24">
                <div>
                    <router-link to="/policy">Back</router-link>
                </div>
                <div>
                    <p class="p2"><b>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ МОБИЛЬНОГО ПРИЛОЖЕНИЯ</b></p>
                    <p class="p2"><b>"EXCURSIO"</b></p>
                    <p class="p2"><b>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</b></p>
                    <p class="p2">"<b>Закон</b>" означает Федеральный закон Российской Федерации "О персональных данных" со
                        всеми изменениями и</p>
                    <p class="p2">дополнениями, а также иные законодательные акты Российской Федерации.</p>
                    <p class="p2">"<b>Контроллер</b>" означает лицо, которое является ответственным за обработку и защиту
                        Персональных данных</p>
                    <p class="p2">Пользователей, находящихся на территории ЕС в понимании Общего регламента защиты
                        персональных
                        данных (General</p>
                    <p class="p2">Data Protection Regulation) от 27 апреля 2016 года (далее "<b>GDRP</b>").</p>
                    <p class="p2">"<b>Мобильное приложение</b>" это программное обеспечение (со всеми существующими
                        дополнениями
                        и улучшениями),</p>
                    <p class="p2">предназначенное для работы на смартфонах, планшетах, часах и других мобильных устройствах,
                        и
                        разработанное для</p>
                    <p class="p2">конкретной платформы (iOS, Android, Windows Phone и т. д.). Для целей настоящей Политики
                        под
                        Мобильным</p>
                    <p class="p2">приложением подразумевается следующее программное обеспечение: EXCURSIO.</p>
                    <p class="p2">"<b>Персональные данные</b>" означает совокупность личных данных и/или
                        неперсонифицированной
                        информации о</p>
                    <p class="p2">Пользователе, предоставляемую им самим Правообладателю и/или автоматически собираемую
                        Правообладателем и/или</p>
                    <p class="p2">третьими лицами.</p>
                    <p class="p2">"<b>Политика</b>" означает настоящею Политику конфиденциальности мобильного приложения (со
                        всеми существующими</p>
                    <p class="p2">дополнениями и изменениями).</p>
                    <p class="p2">"<b>Пользователь</b>" означает юридическое или физическое лицо, которое загрузило
                        Мобильное
                        приложение на смартфон,</p>
                    <p class="p2">планшет, часы или любое другое мобильное устройство и/или осуществило активацию такого
                        Мобильного приложения на</p>
                    <p class="p2">одном из указанных устройств.</p>
                    <p class="p2">"<b>Пользовательское соглашение</b>" означает соглашение, заключаемое между
                        Правообладателем и
                        Пользователем в</p>
                    <p class="p2">отношении порядка, правил и особенностей использования Пользователем Мобильного
                        приложения.
                        Пользователь</p>
                    <p class="p2">присоединяется к такому соглашению и не имеет права вносить и/или требовать внесения в
                        него
                        каких-либо изменений</p>
                    <p class="p2">или дополнений.</p>
                    <p class="p2">"<b>Правообладатель</b>" означает следующее лицо, которому принадлежат исключительные
                        права
                        владения Мобильным</p>
                    <p class="p2">приложением</p>
                    <p class="p2"><b><i>BAKU PRODUKSIYON KONGRE ORGANIZASYON OZEL EGITIM DANISMANLIGI VE TURIZM TIC LTD
                                STI</i></b><i>, адрес</i></p>
                    <p class="p2"><i>местонахождения: Сaglayan, 2054. Sk. 29/2, 07230 Муратпаша/Анталия, Турция</i></p>
                    <p class="p2"><b>«Партнер» </b>- означает следующее юридическое лицо: ООО "Траектория", адрес
                        местонахождения: Россия, г. Москва, ул.</p>
                    <p class="p2">2ая Рощинская, дом 10, помещение 113, связанное договорными обязательствами с Компанией.
                    </p>
                    <p class="p2">"<b>Процессор</b>" означает лицо, которое в понимании GDRP по поручению Контроллера
                        выполняет
                        хранение и/или обработку</p>
                    <p class="p2">Персональных данных, полученных от Пользователей.</p>
                    <p class="p2">"<b>Файлы куки</b>" означает небольшие файлы, отправляемые каким-либо мобильным
                        приложениям
                        или сайтом, и</p>
                    <p class="p2">размещаемые на смартфонах Пользователя, для улучшения работы таких приложений или сайтов,
                        а
                        также качества</p>
                    <p class="p2">размещенного в них контента.</p>
                    <p class="p2"><b>2. ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА</b></p>
                    <p class="p2"><b>Общие положения</b></p>
                    <p class="p2">Данная Политика используется и применима исключительно к Персональным данным, получаемым
                        от
                        Пользователя в</p>
                    <p class="p2">связи с использованием им Мобильного приложения. Положения данной Политики направлены на:
                    </p>
                    <p class="p2"><b>(1)</b> определение видов и типов получаемых Персональных данных, направлений и целей
                        использования (обработки)</p>
                    <p class="p2">Персональных данных, а также источников получения таких Персональных данных; и<span
                            class="s1"><i>2/8</i></span></p>
                    <p class="p2"><b>(2)</b> определение прав Пользователя в отношении защиты конфиденциальности
                        передаваемых им
                        Персональных</p>
                    <p class="p2">данных; и</p>
                    <p class="p2"><b>(3)</b> определение лиц, ответственных за обработку и хранение Персональных данных, а
                        также
                        третьих лиц, которым</p>
                    <p class="p2">такие данные раскрываются (полностью или частично).</p>
                    <p class="p2">Правила настоящей Политики не применяются в случае обработки третьими лицами Персональных
                        данных, которые</p>
                    <p class="p2">добровольно предоставляются Пользователем.</p>
                    <p class="p2">Посредством установки и/или активации Мобильного приложения на смартфоне Пользователь
                        соглашается с условиями</p>
                    <p class="p2">данной Политики и дает свое согласие Правообладателю на сбор, обработку, удержание и
                        хранение
                        Персональных данных</p>
                    <p class="p2">в порядке и на условиях, предусмотренных настоящей Политикой.</p>
                    <p class="p2">Если Пользователь не согласен с условиями Политики и/или отдельные условия Политики ему не
                        понятны, в таком случае</p>
                    <p class="p2">Пользователь обязан немедленно прекратить использование Мобильного приложения.</p>
                    <p class="p2"><b>Права пользователя по защите персональных данных</b></p>
                    <p class="p2">В связи с предоставлением Персональных данных Пользователь автоматически получает
                        следующие
                        права:</p>
                    <p class="p2"><b>(1)</b> получать данные, касающиеся их обработки (основания и цели такой обработки,
                        применяемые способы обработки,</p>
                    <p class="p2">сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на
                        основании
                        договора или</p>
                    <p class="p2">Закона).</p>
                    <p class="p2"><b>(2)</b> получать данные о месте нахождения и идентификационных данных лиц, совершающих
                        обработку Персональных</p>
                    <p class="p2">данных.</p>
                    <p class="p2"><b>(3)</b> получать данные о сроках хранения Персональных данных.</p>
                    <p class="p2"><b>(4)</b> получать данные об осуществленной или о предполагаемой трансграничной передаче
                        Персональных данных.</p>
                    <p class="p2"><b>(5)</b> получать информацию о месте нахождения и идентификационных данных лиц,
                        совершающих
                        хранение</p>
                    <p class="p2">Персональных данных.</p>
                    <p class="p2"><b>(6)</b> обжаловать действия или бездействие Правообладателя в уполномоченный орган по
                        защите прав субъектов</p>
                    <p class="p2">персональных данных или в судебном порядке.</p>
                    <p class="p2"><b>(7)</b> получать возмещение убытков и/или компенсацию морального вреда в судебном
                        порядке в
                        следствие допущенных</p>
                    <p class="p2">Правообладателем и/или третьими лицами нарушений прав Пользователя на охрану и защиту его
                        Персональных</p>
                    <p class="p2">данных.</p>
                    <p class="p2"><b>(8</b> реализовывать иные права в области защиты персональных данных, предусмотренные
                        Законом или положениями</p>
                    <p class="p2">данной Политики.</p>
                    <p class="p2"><b>3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</b></p>
                    <p class="p2"><b>Неперсонифицированная информация о пользователях</b></p>
                    <p class="p2">В связи с использованием Мобильного приложения Правообладатель может автоматически
                        собирать и
                        обрабатывать</p>
                    <p class="p2">следующею неперсонифицированную информацию о Пользователе:</p>
                    <p class="p2"><b>(1)</b> информацию о трафике, возможном количестве совершенных кликов, логи и другие
                        данные.</p>
                    <p class="p2"><b>(2)</b> информацию о месте нахождении Пользователя (геолокация). Пользователь может в
                        любой
                        момент отключить</p>
                    <p class="p2">геолокацию путем изменения настроек устройства, с которого осуществлен вход в Мобильное
                        приложение.</p>
                    <p class="p2">Геолокация используется Мобильным приложением только тогда, когда Пользователь активно
                        использует такое</p>
                    <p class="p2">приложение. При выходе из Мобильного приложения геолокация перестает функционировать.</p>
                    <p class="p2"><b>(3)</b> информацию об устройстве (идентификационный номер, сеть мобильного оператора),
                        с
                        которого выполняется</p>
                    <p class="p2">вход, операционная система, платформа, тип браузера и другая информация о браузере, IP
                        адрес.
                    </p>
                    <p class="p2"><b>Личные данные о пользователях</b><span class="s1"><i>3/8</i></span></p>
                    <p class="p2">Пользователь предоставляет Правообладателю о себе следующие личные данные:</p>
                    <p class="p2"><b>(1)</b> полные фамилию, имя и отчество.</p>
                    <p class="p2"><b>(2)</b> адрес электронной почты.</p>
                    <p class="p2"><b>(3)</b> номер мобильного телефона.</p>
                    <p class="p2"><b>(4)</b> данные, которые содержатся в личном кабинете (профиле) Пользователя, все
                        внутренние
                        переписки Пользователя</p>
                    <p class="p2">(если есть), а также иная активность личного кабинета (профиля) Пользователя.</p>
                    <p class="p2"><b>(5)</b> данные о совершенных Пользователям заказах/покупках и/или полученных/оплаченных
                        услугах через Мобильное</p>
                    <p class="p2">приложение.</p>
                    <p class="p2"><b>(6)</b> данные и информация, получаемая в результате объединения тех или иных
                        Персональных
                        данных конкретного</p>
                    <p class="p2">Пользователя, а также данные и информация, получаемые данные о Пользователе, получаемые от
                        третьих лиц</p>
                    <p class="p2">(партнеров, маркетологов, исследователей).</p>
                    <p class="p2">Пользователь является единственным ответственным лицом за полноту предоставляемых личных
                        (персональных) данных</p>
                    <p class="p2">и обязан осуществлять своевременное их изменение (обновление, проверку, корректировку) на
                        регулярной основе.</p>
                    <p class="p2">Правообладатель исходит из того, что все предоставленные Пользователем личные
                        (персональные)
                        данные являются</p>
                    <p class="p2">достоверными, и что Пользователь поддерживает такую информацию в актуальном состоянии.</p>
                    <p class="p2"><b>Информация о совершаемых транзакциях</b></p>
                    <p class="p2">Пользователь через Мобильное приложение может осуществлять оплату за товары или услуги,
                        посредством введения в</p>
                    <p class="p2">специальное поле информации о платежной карте и идентификационных данных собственника
                        такой
                        карты. Пользователь</p>
                    <p class="p2">может совершать оплату в Мобильном приложении следующим способом:</p>
                    <p class="p2"><span class="s2">•</span> посредством банковской карты.</p>
                    <p class="p2">Сбор и обработка данных о Пользователе в данном случае осуществляется исключительно для
                        целей
                        проведения оплаты,</p>
                    <p class="p2">недопущения мошенничества, а также соблюдения иных требований Закона.</p>
                    <p class="p2">Пользователь дает свое согласие на доступ и сбор Правообладателем и соответствующей
                        платежной
                        системой или</p>
                    <p class="p2">банковским учреждением, через которую/которое проводится оплата, к таким Персональным
                        данным,
                        а также соглашается</p>
                    <p class="p2">с политикой конфиденциальности соответствующей платежной системы или банковского
                        учреждения.
                    </p>
                    <p class="p2"><b>Использование файлов куки</b></p>
                    <p class="p2">Данное Мобильное приложение с разрешения Пользователя применяет определенные Файлы куки
                        для
                        сохранения IP-</p>
                    <p class="p2">адреса, предпочтений Пользователей или типа используемого устройства с целью <b>(1)</b>
                        ведения статистики посещений и</p>
                    <p class="p2">трафика Приложения и <b>(2)</b> персонализации выводимых на экран Пользователя данных, и
                        <b>(3)</b> сохранения данных,
                    </p>
                    <p class="p2">необходимых для идентификации Пользователя, в том числе при доступе с разных устройств, и
                        <b>(4)</b> показа рекламы в
                    </p>
                    <p class="p2">соответствии с интересами и предпочтениями Пользователя. Мобильное приложение может
                        использовать как собственные</p>
                    <p class="p2">Файлы куки, принадлежащие Правообладателю, так и Файлы куки третьих лиц.</p>
                    <p class="p2">Мобильное приложение использует следующие Файлы куки:</p>
                    <p class="p2"><b>(1)</b> <i>Технические (функциональные) Файлы куки</i>, которые нужны для контроля
                        трафика
                        и передачи данных, для</p>
                    <p class="p2">идентификации Пользователей и предоставления доступа Пользователю к контенту Мобильного
                        приложения и без</p>
                    <p class="p2">которых использование Мобильного приложения является функционально ограниченным, а также
                        для
                    </p>
                    <p class="p2">предотвращения предоставления рекомендаций, не соответствующих интересам Пользователя.</p>
                    <p class="p2"><b>(2)</b> <i>Статистические Файлы куки</i>, которые нужны для отслеживания частоты
                        посещаемости сайта Пользователями,</p>
                    <p class="p2">для выявления способов использования Пользователем Мобильного приложения, а также для
                        выявления типа и вида</p>
                    <p class="p2">контента, который является популярными или интересным для Пользователя.</p>
                    <p class="p2"><b>(3)</b> <i>Геолокационные Файлы куки</i>, которые нужны для определения места
                        нахождения
                        Пользователя для</p>
                    <p class="p2">персонализации выводимого на экран его устройства контента в Мобильном приложении.</p>
                    <p class="p2"><b>(4)</b> <i>Рекламные (маркетинговые) Файлы куки</i>, которые нужные для размещения
                        рекламных и/или маркетинговых</p>
                    <p class="p2">объявлений в Мобильном приложении, которые соответствуют предпочтениями и интересам
                        Пользователя.<span class="s1"><i>4/8</i></span></p>
                    <p class="p2"><b>(5)</b> <i>Файлы куки третьих лиц</i>, которые устанавливаются третьими лицами с
                        разрешения
                        Пользователя и</p>
                    <p class="p2">предназначены для проведения статистических исследований, касающихся поведения
                        Пользователя в
                        сети Интернет</p>
                    <p class="p2">и/или направления персонализиированных рекламных или маркетинговых материалов Пользователю
                        и/или</p>
                    <p class="p2">предоставления товаров или услуг.</p>
                    <p class="p2">Пользователь имеет право в любой момент запретить использование Файлов куки в Мобильном
                        приложении путем</p>
                    <p class="p2">изменения определенных настроек в своем смартфоне. Такое отключение может повлечь за собой
                        ограничение или</p>
                    <p class="p2">изменение доступа Пользователя к функциональным возможностям Мобильного приложения и/или
                        контенту. Для</p>
                    <p class="p2">отключения Файлов куки необходимо на своем смартфоне запретить устройству доступ к камере,
                        геолокации и пр.</p>
                    <p class="p2"><b>4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</b></p>
                    <p class="p2"><b>Определение целей обработки</b></p>
                    <p class="p2">Сбор и обработка Персональных данных осуществляется в следующих целях:</p>
                    <p class="p2"><b>(1)</b> для анализа поведения Пользователя, а также выявления предпочтений Пользователя
                        к
                        определенному виду</p>
                    <p class="p2">контента.</p>
                    <p class="p2"><b>(2)</b> для оперативной и корректной работы Мобильного приложения, улучшения
                        функционирования работы</p>
                    <p class="p2">Мобильного приложения, улучшения контента Мобильного приложения, улучшения внутренней
                        архитектуры и</p>
                    <p class="p2">функциональности Мобильного приложения.</p>
                    <p class="p2"><b>(3)</b> для идентификации Пользователя.</p>
                    <p class="p2"><b>(4)</b> для предоставления персонализированных рекламных и маркетинговых материалов.
                    </p>
                    <p class="p2"><b>(5)</b> для соблюдения требований Закона.</p>
                    <p class="p2"><b>(6)</b> для отслеживания заказов/покупок, совершенных Пользователем через Мобильное
                        приложение.</p>
                    <p class="p2"><b>(7)</b> для определения места нахождения Пользователя.</p>
                    <p class="p2"><b>(8)</b> для технической поддержки Мобильного приложения, выявления проблем в его работе
                        и
                        их устранение.</p>
                    <p class="p2"><b>(9)</b> для поддержания связи с Пользователем (коммуникация).</p>
                    <p class="p2"><b>(10)</b> для выполнения иных обязательств Правообладателя, которые возникли перед
                        Пользователем.</p>
                    <p class="p2"><b>(11)</b> для проведения статистических исследований.</p>
                    <p class="p2"><b>(12)</b> для любых других целей, при условии получения отдельного согласия от
                        Пользователя.
                    </p>
                    <p class="p2">Обработка Персональных данных осуществляется на основе принципов: <b>(1)</b> законности
                        целей
                        и способов обработки; и <b>(2)</b></p>
                    <p class="p2">добросовестности; и <b>(3)</b> соответствия целей обработки Персональных данных целям,
                        заранее
                        определенным и заявленным</p>
                    <p class="p2">при сборе таких Персональных данных; и <b>(4)</b> соответствия объема и характера
                        обрабатываемых Персональных данных</p>
                    <p class="p2">заявленным целям их обработки.</p>
                    <p class="p2"><b>Условия обработки персональных данных</b></p>
                    <p class="p2">Обработка Персональных данных проводится в случаях: <b>(1)</b> получения согласия от
                        Пользователя; или <b>(2)</b> достижения</p>
                    <p class="p2">Правообладателем целей, предусмотренных международным договором или Законом; или
                        <b>(3)</b>
                        предоставления
                    </p>
                    <p class="p2">Пользователем своих Персональных данных неограниченному кругу лицу; или <b>(4)</b>
                        выполнения
                        иных обязательств</p>
                    <p class="p2">Правообладателя перед Пользователем, включая, однако не ограничиваясь, предоставление
                        определенного контента</p>
                    <p class="p2">Пользователю; или <b>(5)</b> спасения жизни или здоровья Пользователя, когда согласие на
                        обработку его Персональных данных</p>
                    <p class="p2">не удается получить заблаговременно.</p>
                    <p class="p2">В случае обезличивания Персональных данных, что не позволяет прямо или опосредованно
                        определить Пользователя,</p>
                    <p class="p2">последующее использование и раскрытие таких данных третьим лицам допускается и в отношении
                        их
                        более не</p>
                    <p class="p2">применяются правила данной Политики.</p>
                    <p class="p2">Правообладатель принимает все возможные меры для защиты конфиденциальности полученных
                        Персональных данных,</p>
                    <p class="p2">за исключением случаев, когда Пользователь сделал такие данные общедоступными.<span
                            class="s1"><i>5/8</i></span></p>
                    <p class="p2">Обработка Персональных данных осуществляется с использованием средств автоматизации и без
                        использования таких</p>
                    <p class="p2">средств автоматизации.</p>
                    <p class="p2"><b>5. ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ</b></p>
                    <p class="p2"><b>Использование ремаркетинговых сервисов</b></p>
                    <p class="p2">Правообладатель использует ремаркетинг для рекламы Пользователю контента Мобильного
                        приложения
                        на других сайтах,</p>
                    <p class="p2">посещаемых Пользователем.</p>
                    <p class="p2">Ремаркетинговые сервисы Правообладателю предоставляются посредством платформы Telegram.
                        Telegram собирает и</p>
                    <p class="p2">обрабатывает неперсонифицированные данные, которые напрямую не позволяют установить или
                        идентифицировать</p>
                    <p class="p2">Пользователя. Собираемая информация, как правило, может включать <b>(1)</b> контент,
                        который
                        просматривал Пользователь,</p>
                    <p class="p2"><b>(2)</b> дату и время, когда Пользователь просматривал контент, <b>(3)</b> данные о
                        геолокации. Сбор и обработка такой</p>
                    <p class="p2">неперсонифицированной информации позволяет предоставлять Пользователю более таргетинговый
                        рекламный или</p>
                    <p class="p2">маркетинговый контент.</p>
                    <p class="p2">Посредством установки Мобильного приложения Пользователь соглашается с политикой
                        конфиденциальности (Privacy</p>
                    <p class="p2">Policy) для Telegram, а также с автоматической установкой на устройство Пользователя
                        соответствующих Файлов куки.</p>
                    <p class="p2">Пользователь имеет право в любой момент отказаться от такой рекламы посредством изменений
                        соответствующих</p>
                    <p class="p2">настроек устройства, с которого совершается вход в Мобильное приложение.</p>
                    <p class="p2"><b>Использование аналитических платформ</b></p>
                    <p class="p2">Правообладатель использует аналитическую платформу Yandex для <b>(1)</b> отслеживания
                        частоты
                        посещаемости мобильного</p>
                    <p class="p2">приложения Пользователями; и <b>(2)</b> отслеживания способов использования Пользователем
                        Мобильного приложения и/или</p>
                    <p class="p2">его контента; и <b>(3)</b> выявления типа и вида контента, который является популярными
                        среди
                        Пользователей; и <b>(4)</b></p>
                    <p class="p2">определения места нахождения Пользователя. Пользователь также дает свое согласие
                        Правообладателю на использование</p>
                    <p class="p2">им информации, полученной о Пользователе от Yandex.</p>
                    <p class="p2">Для указанных целей аналитическая платформа Yandex App Metrica может собирать данные об IP
                        адресе, геолокации,</p>
                    <p class="p2">поведении Пользователя, а также его предпочтениях и интересе в отношении определенного
                        контента.</p>
                    <p class="p2">Аналитическая платформа Yandex получает доступ к Персональным данным с тем, чтоб
                        предоставить
                        Правообладателю</p>
                    <p class="p2">понимание того насколько эффективно работает его Мобильное приложение, какой именно
                        контент
                        является популярным,</p>
                    <p class="p2">насколько эффективно размещение в нем той или иной рекламы, а также для целей разработки
                        и/или
                        улучшения</p>
                    <p class="p2">существующей маркетинговой стратегии Правообладателя.</p>
                    <p class="p2">Посредством установки Мобильного приложения Пользователь соглашается с политикой
                        конфиденциальности (Privacy</p>
                    <p class="p2">Policy) Yandex, а также с автоматической установкой на устройство Пользователя
                        соответствующих
                        Файлов куки.</p>
                    <p class="p2"><b>Раскрытие персональных данных третьим лицам</b></p>
                    <p class="p2">Правообладатель имеет право раскрывать Персональные данные <b>(1)</b> своим аффилированным
                        лицам, филиалам и</p>
                    <p class="p2">представительствам, открытым как на территории Российской Федерации, так и на территории
                        других государств; <b>(2)</b></p>
                    <p class="p2">правопреемникам Правообладателя, которые возникли в результате его ликвидации,
                        реорганизации
                        или банкротства, и</p>
                    <p class="p2">которые получили исключительные права владения Мобильным приложением; <b>(3)</b>
                        поставщикам
                        платежных услуг или</p>
                    <p class="p2">банковским (финансовым) учреждениям, для проведения транзакций Пользователя через
                        Мобильное
                        приложение; <b>(4)</b></p>
                    <p class="p2">третьим лицам исключительно для целей оказания получения Пользователем определенного
                        контента
                        или доступа к нему;</p>
                    <p class="p2"><b>(5)</b> третьим лицам, когда Пользователем было дано согласие на раскрытие, передачу
                        или
                        обработку своих Персональных</p>
                    <p class="p2">данных, а также в иных случаях, прямо предусмотренных Законом или данной Политикой.</p>
                    <p class="p2">Правообладатель раскрывает Персональные данные только в том случае, если <b>(1)</b>
                        уверен,
                        что третьи лица будут соблюдать</p>
                    <p class="p2">условия данной Политики и предпринимать такие же меры по защите конфиденциальности
                        Персональных данных,</p>
                    <p class="p2">которые предпринимает сам Правообладатель, и <b>(2)</b> согласие на такое раскрытие было
                        предварительно выражено</p>
                    <p class="p2">Пользователем и/или допускается на основании Закона.</p>
                    <p class="p2"><b>Реклама от третьих лиц</b></p>
                    <p class="p2">Контент Мобильного приложения может содержать рекламные баннеры и/или ссылки на сайты
                        третьих
                        лиц.</p>
                    <p class="p2">Использование Пользователем таких сайтов (путем перехода по ссылке или любым другим
                        способом)
                        может повлечь за<span class="s1"><i>6/8</i></span></p>
                    <p class="p2">собой сбор, обработку и использование Персональных данных, а также возможную
                        автоматическую
                        передачу Файлов куки</p>
                    <p class="p2">на устройство Пользователя, с которого совершается переход на сайт третьих лиц.
                        Правообладатель не несет какой-либо</p>
                    <p class="p2">ответственности за способы, методы и порядок обработки Персональных данных сайтами третьих
                        лиц. В следствие чего</p>
                    <p class="p2">Правообладатель также не является ответственным лицом в случае раскрытия Персональных
                        данных
                        неограниченному</p>
                    <p class="p2">кругу лиц в связи с использованием Пользователем таких сайтов.</p>
                    <p class="p2">Правообладатель настоятельно рекомендует каждому Пользователю детально ознакомиться с
                        политиками защиты</p>
                    <p class="p2">персональных данных используемых сайтов.</p>
                    <p class="p2">Пользователь имеет право в любой момент отключить такие рекламные баннеры и/или ссылки
                        посредством выполнения</p>
                    <p class="p2">следующих действий:</p>
                    <p class="p2"><i>В личном кабинете пользователя перейти в "Настройки", затем найти операцию "рекламные
                            материалы" и убрать</i></p>
                    <p class="p2"><i>напротив неё галочку</i></p>
                    <p class="p2"><b>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ</b></p>
                    <p class="p2"><b>Реклама в мобильном приложении</b></p>
                    <p class="p2">Правообладатель вместе с контентом размещает в Мобильном приложении различные рекламные и
                        маркетинговые</p>
                    <p class="p2">материалы с учетом выявленных предпочтений Пользователя к тому или иному контенту.</p>
                    <p class="p2"><b>7. НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ</b></p>
                    <p class="p2"><b>Требование о прекращении обработки персональных данных</b></p>
                    <p class="p2">Каждый Пользователь имеет право выразить свое возражение Правообладателю против обработки
                        и/или хранения его</p>
                    <p class="p2">Персональных данных. Такое возражение может быть выражено следующим образом:</p>
                    <p class="p2"><i>Направить запрос на адрес электронной почты, указанный в мобильном приложении</i></p>
                    <p class="p2"><b>Запрос на получение информации о персональных данных</b></p>
                    <p class="p2">Если у Пользователя возникают вопросы, связанные с порядком применения или использования
                        настоящий Политики,</p>
                    <p class="p2">порядком и/или способом обработки Персональных данных, Пользователь может задать такой
                        вопрос
                        следующим</p>
                    <p class="p2">образом:</p>
                    <p class="p2"><i>Вопрос должен быть направлен Правообладателю на адрес электронной почты, указанный в
                            мобильном</i></p>
                    <p class="p2"><i>приложении</i></p>
                    <p class="p2"><b>Изменение (обновление, дополнение, корректировка) или удалении персональных данных</b>
                    </p>
                    <p class="p2">Пользователь имеет право в любой момент самостоятельно изменить или удалить свои
                        Персональные
                        данные, за</p>
                    <p class="p2">исключением случаев, когда такое изменение или удаление может привести <b>(1)</b> к
                        нарушению
                        правил настоящей Политики;</p>
                    <p class="p2">или <b>(2)</b> к нарушению Закона; <b>(3)</b> характер таких Персональных данных является
                        доказательством в каком-либо судебном</p>
                    <p class="p2">процессе, возникшем между Правообладателем и Пользователем. Для этого Пользователю
                        требуется
                        удалить свой личный</p>
                    <p class="p2">аккаунт (профиль) в Мобильном приложении.</p>
                    <p class="p2">Правообладатель имеет право в любой момент удалить личный аккаунт/профиль Пользователя, а
                        также все Персональные</p>
                    <p class="p2">данные о Пользователе, если он нарушил условия данной Политики и/или Пользовательского
                        соглашения.</p>
                    <p class="p2"><b>8. СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</b></p>
                    <p class="p2">Хранение осуществляется третьими лицами по поручению Правообладателя. Пользователь дает
                        свое
                        согласие на хранение</p>
                    <p class="p2">его Персональных данных третьими лицами по поручению Правообладателя, при условии
                        сохранения
                        такими третьими</p>
                    <p class="p2">лицами конфиденциальности полученных Персональных данных. Хранение Персональных данных
                        осуществляется на</p>
                    <p class="p2">территории Российской Федерации в соответствии с действующим законодательством.<span
                            class="s1"><i>7/8</i></span></p>
                    <p class="p2">Хранение осуществляется в течение всего срока, необходимого для достижения заявленных
                        целей
                        обработки</p>
                    <p class="p2">Персональных данных.</p>
                    <p class="p2">Правообладатель обязуется немедленно после достижения целей обработки Персональных данных
                        уничтожить их или же</p>
                    <p class="p2">обезличить.</p>
                    <p class="p2"><b>9. ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К МОБИЛЬНОМУ ПРИЛОЖЕНИЮ</b></p>
                    <p class="p2"><b>Пользователи на территории Российской Федерации</b></p>
                    <p class="p2">Использование Мобильного приложения предназначено для лиц старше 18 лет, которые получают
                        к
                        нему доступ только</p>
                    <p class="p2">при условии предоставления предварительного согласия на обработку их Персональных данных.
                        Правообладатель</p>
                    <p class="p2">проверяет возраст Пользователя следующим образом:</p>
                    <p class="p2"><i>Пользователю потребуется ввести подтверждение того, что его возраст старше 18 лет</i>
                    </p>
                    <p class="p2">Если Пользователь является несовершеннолетним лицом, в таком случае он должен немедленно
                        прекратить использование</p>
                    <p class="p2">данного Мобильного приложения.</p>
                    <p class="p2"><b>Пользователи на территории Европейского Союза</b></p>
                    <p class="p2">Использование Мобильного приложения предназначено для лиц в возрасте 16 лет и старше,
                        которые
                        получают к нему</p>
                    <p class="p2">доступ только при условии предоставления предварительного согласия на обработку их
                        Персональных данных.</p>
                    <p class="p2">Правообладатель проверяет возраст Пользователя следующим образом:</p>
                    <p class="p2"><i>Пользователю потребуется ввести подтверждение того, что его возраст старше 16 лет</i>
                    </p>
                    <p class="p2">Если Правообладателю стало известно, что возраст Пользователя не соответствует допустимому
                        возрасту для пользования</p>
                    <p class="p2">Мобильным приложением, в таком случае Правообладатель обязуется незамедлительно
                        заблокировать
                        доступ такому</p>
                    <p class="p2">Пользователю к Мобильному приложению.</p>
                    <p class="p2"><b>10. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</b></p>
                    <p class="p2">Защита конфиденциальности Персональных данных является первостепенной и важной задачей для
                        Правообладателя.</p>
                    <p class="p2">Правообладатель придерживается всех требуемых международных стандартов, правил и
                        рекомендаций
                        по защите</p>
                    <p class="p2">Персональных данных.</p>
                    <p class="p2">Правообладатель внедрил ряд технических и организационных методов, направленных на защиту
                        Персональных данных</p>
                    <p class="p2">от разглашения или несанкционированного доступа к ним третьих лиц. Для обеспечения
                        сохранности
                        и</p>
                    <p class="p2">конфиденциальности получаемых Персональных данных Правообладатель использует следующие
                        средства защиты:</p>
                    <p class="p2"><b>(1)</b> SSL (Security Sockets Layer) протокол.</p>
                    <p class="p2"><b>(2)</b> SET (Secure Electronic Transaction) протокол.</p>
                    <p class="p2"><b>(3)</b> автоматическое сохранение данных.</p>
                    <p class="p2"><b>(4)</b> Firewalls.</p>
                    <p class="p2"><b>11</b>. <b>ПОЛЬЗОВАТЕЛИ, НАХОДЯЩИЕСЯ НА ТЕРРИТОРИИ ЕВРОПЕЙСКОГО СОЮЗА</b></p>
                    <p class="p2"><b>Общие положения</b></p>
                    <p class="p2">Поскольку Мобильное приложение является доступным для пользователей из Европейского союза,
                        Правообладатель</p>
                    <p class="p2">обязуется дополнительно придерживаться положений GDRP.</p>
                    <p class="p2">Контроллером в понимании настоящей Политики является Правообладатель. Процессором в
                        понимании
                        настоящей</p>
                    <p class="p2">Политики является Хранитель согласно Статье 8 Политики.<span class="s1"><i>8/8</i></span>
                    </p>
                    <p class="p2">Правообладатель осуществляет хранение Персональных данных в течение разумного срока,
                        необходимого для достижения</p>
                    <p class="p2">целей обработки, однако не меньше срока, установленного локальным законодательством
                        государства-члена Европейского</p>
                    <p class="p2">союза, на территории которого доступно Мобильное приложение, для хранения того или иного
                        вида
                        Персональных</p>
                    <p class="p2">данных. По истечению срока, установленного для хранения, Правообладатель обязуется
                        немедленно
                        уничтожить или</p>
                    <p class="p2">обезличить такие данные.</p>
                    <p class="p2"><b>Официальный представитель</b></p>
                    <p class="p2">Поскольку Контроллер находится за пределами территории Европейского союза, официальным его
                        представителем по</p>
                    <p class="p2">защите Персональных данных Пользователей в Европейском Союзе назначается следующее лицо:
                        Г-н
                        Emil Abdullazade,</p>
                    <p class="p2">адрес: Via Dell’Annunciata 23/4 CAP 20121, Milano (MI), контактные данные:
                        emil@greencode.com.tr.</p>
                    <p class="p2"><b>Права пользователей в области защиты персональных данных</b></p>
                    <p class="p2">Согласно Главе 3 GDRP Пользователи, находящиеся на территории Европейского Союза, имеют
                        следующие права в</p>
                    <p class="p2">области защиты Персональных данных: <b>(1)</b> право на получение информации о своих
                        Персональных данных ("the right to</p>
                    <p class="p2">be informed"); и <b>(2)</b> право на доступ к своим Персональным данным ("the right of
                        access"); и <b>(3)</b> право на исправление</p>
                    <p class="p2">Персональных данных ("the right to rectification"); и <b>(4)</b> право на уничтожение
                        Персональных данных ("the right to erasure");</p>
                    <p class="p2">и <b>(5)</b> право на ограничение обработки Персональных данных ("the right to restrict
                        processing"); и <b>(6)</b> право на перенос</p>
                    <p class="p2">Персональных данных третьим лицам ("the right to data portability"); и <b>(7)</b> право на
                        возражение ("the right to object").</p>
                    <p class="p2"><b>12</b>. <b>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b></p>
                    <p class="p2"><b>Доступность текста политики для ознакомления</b></p>
                    <p class="p2">Данная Политика может быть переведена на иностранный язык для тех Пользователей, которые
                        осуществляют доступ к</p>
                    <p class="p2">Мобильному приложению за пределами Российской Федерации. В случае расхождения текста
                        оригинала
                        (русский язык) и</p>
                    <p class="p2">его перевода, преимущественную силу имеет язык оригинала.</p>
                    <p class="p2">Данная редакция Политики действует от 1 августа 2023 годa.</p>
                    <p class="p2"><b>Изменение и дополнение политики</b></p>
                    <p class="p2">Настоящая Политика может быть изменена время от времени. Правообладатель не несет
                        какой-либо
                        ответственности перед</p>
                    <p class="p2">Пользователем за изменение условий данной Политики без разрешения и/или согласия
                        Пользователя.
                    </p>
                    <p class="p2">Пользователь сам обязуется на регулярной основе проверять положения данной Политики на
                        предмет
                        ее возможного</p>
                    <p class="p2">изменения или дополнения.</p>
                    <p class="p2"><b>Применимое законодательство</b></p>
                    <p class="p2">Настоящая Политика разработана в соответствие с действующим законодательством о защите
                        персональных данных</p>
                    <p class="p2">Российской Федерации, в частности, с нормами Федерального закона от 27 июля 2006 года №
                        152-ФЗ
                        "О персональных</p>
                    <p class="p2">данных" (со всеми дополнениями и изменениями), Федерального закона от 21 июля 2014 года №
                        242-ФЗ "О внесении</p>
                    <p class="p2">изменений в отдельные законодательные акты Российской Федерации в части уточнения порядка
                        обработки персональных</p>
                    <p class="p2">данных в информационно-телекоммуникационных сетях" (со всеми дополнениями и изменениями),
                        а
                        также положениями</p>
                    <p class="p2">Общего регламента защиты персональных данных (General Data Protection Regulation) от 27
                        апреля
                        2016 года GDRP.</p>
                    <p class="p2"><b>Риск разглашения</b></p>
                    <p class="p2">Вне зависимости от предпринимаемых Правообладателем мер защиты конфиденциальности
                        получаемых
                        персональных</p>
                    <p class="p2">данных, Пользователь настоящим считается должным образом ознакомлен с тем, что любая
                        передача
                        Персональных</p>
                    <p class="p2">данных в сети Интернет не может быть гарантировано безопасной, а потому Пользователь
                        осуществляет такую передачу</p>
                    <p class="p2">на свой собственный риск.</p>
                </div>
            </Grid>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'LoginPage',
    // components: {
    //     vueTelInput
    // },
    mixins: [globalMethods],
    data() {
        return {
            defaultCountry: 'ru',
            login: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
            },
            password: '',
            remember: false,
            value: String,
            dialog: false
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
    },
    watch: {

    },
    methods: {
    }
}
</script>
<style lang="scss">
.policy {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10px;
    gap: 20px;
    width: 100%;
    min-height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .content-block {
        padding: 22px 49px;
        background: #fff;
    }
}
</style>

<template>
    <div id="excursions-page">
        <Grid column="2" :style="gridStyle">
            <div class="caption-block">
                <h4>Экскурсии</h4>
                <span>Экскурсии</span>
            </div>
            <v-btn class="button mt-6" variant="tonal" color="primary" block @click="$router.push('/excursions/new')">
                Добавить экскурсию
            </v-btn>
            <Grid column="4" gap="24" :style="{ gridColumn: '1/3' }">
                <SelectAutocomplete v-model="filter.city_id" placeholder="Выберите город"></SelectAutocomplete>
                <v-text-field v-model="filter.name" autocomplete="off" outlined class="input autocomplete"
                    placeholder="Поиск экскурсии">
                    <template #append>
                        <v-icon>mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </Grid>
            <Block :style="{ gridColumn: '1/3', padding: '0', overflow: 'hidden' }">
                <TableBlock>
                    <template v-slot:head>
                        <tr>
                            <th>Фото</th>
                            <th class="name">Название</th>
                            <th>Активная</th>
                            <th>Тип экскурсии</th>
                            <th>Вид экскурсии</th>
                            <th>Длительность</th>
                            <th>Способ передвижения</th>
                            <th>Краткое описание</th>
                            <th>Стоимость</th>
                            <th>Дата</th>
                            <th>Действия</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="(tour, tInd) of excursions" :key="'tour-' + tour.id">

                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                <img :src="mediaServerUrl + tour.images[0]" alt="">
                            </td>
                            <td class="name" @click="$router.push('/excursions/card/' + tour.id)">
                                {{ JSON.parse(tour.title)[language] }}
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                {{ tour.visible == 0 ? 'нет' : 'да' }}
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                {{ tour.individual == 0 ? 'Групповая' : 'Индивидуальная' }}
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                <ul>
                                    <li v-for="t in tour.tour_types" :key="t">
                                        {{ tourType[t] }}
                                    </li>
                                </ul>
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                {{ duration('' + tour.duration) }}
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                {{ transportTypes[tour.transport_type] }}
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                {{ JSON.parse(tour.descr)[language] }}
                            </td>
                            <td @click="$router.push('/excursions/card/' + tour.id)">
                                <div>от {{ tour.child_price }}$</div>
                            </td>
                            <td>
                                <div class="tour_date" v-for="(s, sInd) of tour.schedule" :key="s.id"
                                    @click="deleteDate(s.id, tInd, sInd)">
                                    {{ s.tour_date }}
                                </div>
                            </td>
                            <td>
                                <div class="btn-row">
                                    <button class="ghost edit-btn" @click="tourSetDate(tour.id)">
                                        <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5"
                                            :iconName="'calendar'" stroke="#003A63" />
                                    </button>
                                    <button class="ghost edit-btn" @click="$router.push(`/excursions/${tour.id}`)">
                                        <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5"
                                            :iconName="'edit'" stroke="#003A63" />
                                    </button>
                                    <button class="ghost delete-btn">
                                        <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5"
                                            :iconName="'trash'" stroke="#D10A0F" @click="delTour(tour.id)" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </template>
                </TableBlock>
                <div class="table-control">
                    <div class="count-items">
                        <span>Строк на странице:</span>
                        <SelectBlock v-model="filter.size" :items="countItems"></SelectBlock>
                    </div>
                    <div class="pagination">
                        <ul>
                            <li>
                                <button class="ghost" @click="changePage('prev')">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0"
                                        :iconName="'chevron-left'" fill="#6F727A" />
                                </button>
                            </li>
                            <li :class="{ actvie: filter.page == '' + showListNow }">1</li>
                            <li :class="{ actvie: filter.page == '' + showListNow }">{{ +filter.page + 1 }}</li>
                            <li>...</li>
                            <li :class="{ actvie: filter.page == showListNow }">{{ countList }}</li>
                            <li>
                                <button class="ghost" @click="changePage('next')">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0"
                                        :iconName="'chevron-right'" fill="#6F727A" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Block>
        </Grid>
        <Modal ref="set-date" :notFooterBorder="true" padding="40">
            <template v-slot:header>
                Запланировать экскурсию
            </template>
            <template v-slot:subheader>
                Выберите день и время экскурсии
            </template>
            <template v-slot:body>
                <Grid column="2" :style="{ gridTemplateColumns: '258px 1fr', gap: '40px' }">
                    <Block padding="20">
                        <div class="row-btn">
                            <button :class="{ 'active': tourDate.date == $moment().format('YYYY-MM-DD') }"
                                @click="tourDate.date = $moment().format('YYYY-MM-DD')">
                                Сегодня
                            </button>
                            <button :class="{ 'active': tourDate.date == $moment().add(1, 'day').format('YYYY-MM-DD') }"
                                @click="tourDate.date = $moment().add(1, 'day').format('YYYY-MM-DD')">
                                Завтра
                            </button>
                        </div>
                        <v-date-picker v-model="tourDate.date" :first-day-of-week="1" locale="ru-RU"
                            :weekday-format="getCustomDay" :multiple="true" :min="$moment().format('YYYY-MM-DD')" range></v-date-picker>
                    </Block>
                    <div class="select-time">
                        <div class="label">Время</div>
                        <div class="inline" v-for="(time, ind) of tourDate.time" :key="'t-' + time">
                            <SelectBlock v-model="tourDate.time[ind]" :items="timeList"></SelectBlock>
                            <button class="ghost border" @click="addTime()" v-if="ind == tourDate.time.length - 1">
                                <Icon width="20" height="20" viewBox="0 0 15 15" strockeWidth="1.5"
                                    :iconName="'plus'" />
                            </button>
                        </div>
                        <ul class="time-list">
                            <li>{{ formatedTourDate }}</li>
                            <!--                            <li v-for="time of tourDate.time" :key="'t-'+time">{{ time }}</li>-->
                        </ul>
                    </div>
                </Grid>
            </template>
            <template v-slot:footer>
                <div class="button-block">
                    <button class="ghost" @click="closeSetDate()">
                        Отменить
                    </button>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="setDate()">
                        Сохранить
                    </v-btn>
                </div>
            </template>
        </Modal>
        <Modal ref="delete-tour" :notFooterBorder="true" padding="40">
            <template v-slot:header>
                <div class="del-icon">
                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'trash'"
                        stroke="#D10A0F" />
                </div>
                Удалить тур
            </template>
            <template v-slot:body>
                Вы действительно хотите удалить тур?
                <div class="color-red">Это действие является постоянным и не может быть отменено!</div>
            </template>
            <template v-slot:footer>
                <div class="button-block">
                    <button class="ghost black" @click="$refs['delete-tour']._cancel()">
                        Отменить
                    </button>
                    <v-btn type="submit" class="button mt-6 error" variant="tonal" color="primary"
                        @click="deleteTourConfirm()">
                        Удалить
                    </v-btn>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { globalMethods } from '@/mixins/globalMethods'
import { VDatePicker } from 'vuetify/lib'

export default {
    name: 'ExcursionsPage',
    mixins: [globalMethods],
    components: {
        VDatePicker,
    },
    data() {
        return {
            tourDate: {
                date: null,
                time: ['09:00']
            },
            selectTime: '',
            timeList: [
                {
                    label: '00:00',
                    value: '00:00',
                },
                {
                    label: '01:00',
                    value: '01:00',
                },
                {
                    label: '02:00',
                    value: '02:00',
                },
                {
                    label: '03:00',
                    value: '03:00',
                },
                {
                    label: '04:00',
                    value: '04:00',
                },
                {
                    label: '05:00',
                    value: '05:00',
                },
                {
                    label: '06:00',
                    value: '06:00',
                },
                {
                    label: '07:00',
                    value: '07:00',
                },
                {
                    label: '08:00',
                    value: '08:00',
                },
                {
                    label: '09:00',
                    value: '09:00',
                },
                {
                    label: '10:00',
                    value: '10:00',
                },
                {
                    label: '11:00',
                    value: '11:00',
                },
                {
                    label: '12:00',
                    value: '12:00',
                },
                {
                    label: '13:00',
                    value: '13:00',
                },
                {
                    label: '14:00',
                    value: '14:00',
                },
                {
                    label: '15:00',
                    value: '15:00',
                },
                {
                    label: '16:00',
                    value: '16:00',
                },
                {
                    label: '17:00',
                    value: '17:00',
                },
                {
                    label: '18:00',
                    value: '18:00',
                },
                {
                    label: '19:00',
                    value: '19:00',
                },
                {
                    label: '20:00',
                    value: '20:00',
                },
                {
                    label: '21:00',
                    value: '21:00',
                },
                {
                    label: '22:00',
                    value: '22:00',
                },
                {
                    label: '23:00',
                    value: '23:00',
                },
            ],
            tourId: null,
            errors: {
                name: '',
                phone: '',
            },
            showListNow: 1,
            countList: 8,
            countItems: [
                {
                    value: '10',
                    label: '10',
                },
                {
                    value: '30',
                    label: '30',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '100',
                    label: '100',
                },
            ],
            gridStyle: { gridTemplateColumns: '1fr 256px', rowGap: '24px' },//, gridTemplateRows: '78px 1fr', height: '100vh'
            admins: [],
            filter: {
                city_id: '',
                name: '',
                page: '1',
                size: '10',
            },
            tourType: {},
            excursions: [],
            transportTypes: {},
            deleteId: null
        }
    },
    computed: {
        ...mapState({
            mediaServerUrl: state => state.mediaServerUrl,
            language: state => state.language,
        }),
        formatedTourDate() {
            if (this.tourDate.date) {
                if (Array.isArray(this.tourDate.date)) {
                    let period = []
                    if (this.tourDate.date[0]) {
                        period.push(this.$moment(this.tourDate.date[0], 'YYYY-MM-DD').format('DD.MM.YYYY'))
                    }
                    if (this.tourDate.date[1]) {
                        period.push(this.$moment(this.tourDate.date[1], 'YYYY-MM-DD').format('DD.MM.YYYY'))
                    }
                    return period.join(' — ')
                } else {
                    return this.$moment(this.tourDate.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                }
            } else {
                return ''
            }
        }
    },
    mounted() {
        this.filterRequest = debounce(this.filterRequest, 500)
        Promise.all([
            this.loadGuide('tourType', '/getTourTypes'),
            this.loadGuide('transportTypes', '/getTransportTypes'),
            this.loadGuide('cities', '/getCities'),
            this.loadGuide('languagesList', '/getLanguages'),
        ]).then(([tourType, transportTypes, cities, languagesList]) => {
            this.$set(this, 'tourType', tourType)
            this.$set(this, 'transportTypes', transportTypes)
            this.$set(this, 'cities', cities)
            this.$set(this, 'languagesList', languagesList)
        })
        this.sendRequest('GET', { page: 1, size: this.filter.size }, '/getTours', {}, true)
            .then(response => {
                let excursions = response,
                    idList = excursions.reduce((result, item) => {
                        return [...result, item['id']]
                    }, [])


                this.sendRequest('POST', {
                    id: idList,
                    adults: 1,
                    child: 1
                }, '/getSchedule', {}, true)
                    .then(response => {
                        let shedules = {}
                        response.forEach(i => {
                            if (!shedules[i.tour_id]) {
                                shedules[i.tour_id] = []
                            }
                            try {
                                if (i.tour_date.indexOf('[') > -1) {
                                    let days = JSON.parse(i.tour_date)
                                    days = days.map(d => {
                                        return this.$moment(d, 'YYYY-MM-DD').format('DD.MM.YYYY')
                                    })
                                    shedules[i.tour_id].push({ ...i, tour_date: days.join(' - ') })
                                } else {
                                    let dt = this.$moment(i.tour_date, 'DD.MM.YYYY'),
                                        now = this.$moment()

                                    if (now.isBefore(dt)) {
                                        shedules[i.tour_id].push(i)
                                    }
                                }
                            } catch (err) {
                                shedules[i.tour_id].push(i)
                            }
                        })
                        excursions = excursions.map(i => {
                            return { ...i, schedule: shedules[i.id] }
                        })
                        this.$set(this, 'excursions', excursions)
                        // this.$set(this, 'schedule', response)
                    })
                    .catch(err => {
                        this.$router.push('/excursions')
                        this.$store.commit('setError', err)
                    })
            })
            .catch(err => {
                this.$store.commit('setError', err)
            })
    },
    watch: {
        filter: {
            deep: true,
            handler(newVal, oldVal) {
                this.filterRequest()
            },
        }
    },
    methods: {
        deleteDate(id, tInd, sInd) {
            this.sendRequest('DELETE', { id }, '/removeSchedule', {}, true)
                .then(response => {
                    this.$delete(this.excursions[tInd].schedule, sInd)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        closeSetDate() {
            this.$set(this, 'tourDate', {
                date: null,
                time: ['09:00']
            })
            this.$refs['set-date']._cancel()
        },
        addTime() {
            // if (this.selectTime) {
            let tmList = [...this.tourDate.time]
            tmList.sort()
            let uniqueArr = [...new Set(tmList)];
            uniqueArr = [...uniqueArr, '']
            this.$set(this.tourDate, 'time', uniqueArr)
        },
        getCustomDay(date) {
            const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        duration(d) {
            d = '' + d
            let [hour, minutes] = d.split('.')
            if (minutes && minutes > 0) {
                if ('' + minutes.length == 1) {
                    minutes = minutes * 10
                }
            }
            if (!minutes) {
                minutes = 0
            }
            return `${hour} ${this.declOfNum(hour, ['час', 'часа', 'часов'])} ${minutes} ${this.declOfNum(minutes, ['минута', 'минуты', 'минут'])}`
        },
        deleteTourConfirm() {
            this.sendRequest('DELETE', { id: this.deleteId }, '/removeTour', {}, true)
                .then(response => {
                    let findIndex = this.excursions.findIndex(a => a.id == this.deleteId)
                    if (findIndex) {
                        this.$delete(this.excursions, findIndex)
                    }
                    this.$set(this, 'deleteId', null)
                    this.$refs['delete-tour']._confirm()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        tourSetDate(tour) {
            this.$set(this, 'tourId', tour)
            this.showModal('set-date')
        },
        delTour(id) {
            this.$set(this, 'deleteId', id);
            this.showModal('delete-tour')
        },
        setDate() {
            let dateList = [...new Set(this.tourDate.date)],
                deteArr = []

            const startDate = this.$moment(dateList[0]);
            const endDate = this.$moment(dateList?.[1] || dateList[0]);
            const currentDate = startDate.clone();

            while (currentDate.isSameOrBefore(endDate)) {
                deteArr.push(currentDate.format('DD.MM.YYYY'))
                currentDate.add(1, 'day');
            }
            let data = deteArr.reduce((result, day) => {
                let timelist = this.tourDate.time.reduce((result, time) => {
                    return [...result, {
                        tour_id: this.tourId,
                        tour_date: day,
                        tour_time: time
                    }]
                }, [])
                return [...result, ...timelist]
            }, [])

            this.sendRequest('POST', { schedule: data }, '/addSchedule', {}, true)
                .then(response => {
                    this.$set(this, 'tourDate', {
                        date: null,
                        time: ['09:00']
                    })
                    this.$refs['set-date']._confirm()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })

        },
        changePage(state) {
            if (state === 'prev') {
                if (this.filter.page > 1) {
                    this.$set(this.filter, 'page', +this.filter.page - 1)
                    this.$set(this, 'showListNow', +this.filter.page - 1)
                }
            } else if (state === 'next') {
                if (this.filter.page < this.countList) {
                    this.$set(this.filter, 'page', +this.filter.page + 1)
                    this.$set(this, 'showListNow', +this.filter.page + 1)
                }
            }
        },
        filterRequest() {

            const filter = Object.entries(this.filter).filter(([_, v]) => !!v).reduce((result, [name, value]) => {
                return { ...result, [name]: value }
            }, {})
            this.sendRequest('GET', filter, '/getTours', {}, true)
                .then(response => {
                    this.$set(this, 'excursions', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)

                })
        }
    }
}
</script>
<style lang="scss">
#excursions-page {
    table {

        th,
        td {
            &.w-85 {
                width: 85px;
            }

            &.name {
                width: 2fr;
            }

            &.phone {
                width: 1fr;
            }

            &.w-150 {
                width: 150px;
            }

            &.name {
                width: 261px;
                min-width: 261px;
            }

            img {
                max-width: 178px;
                width: 178px;
            }

            .btn-row {
                display: flex;
                align-items: center;
            }
        }
    }

    .table-control {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px;
        border-top: 1px solid #809CB1;

        .count-items {
            display: flex;
            align-items: center;
            margin-right: 48px;

            >span {
                display: block;
                margin-right: 16px;
            }

            .my-select {
                width: 70px;
            }
        }

        .pagination {
            ul {
                display: flex;
                align-items: center;
                border: 1px solid #809CB1;
                border-radius: 4px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    width: 42px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: #809CB1;

                    &.active {
                        color: #333333;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid #809CB1;
                    }
                }
            }

        }
    }

    .edit-btn {
        margin-right: 15px !important;
        padding-right: 15px !important;
        position: relative;

        &:after {
            content: "";
            width: 1px;
            height: 20px;
            background: #D9D9D9;
            position: absolute;
            right: 0px;
            display: block;
        }
    }

    .new-admin-form {
        label {
            display: block;
            margin-bottom: 40px;

            >span {
                display: block;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #6F727A;
                margin-bottom: 8px;
            }
        }

        .row-select {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;

            label {
                margin-bottom: 0px;
            }
        }
    }

    .button-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ghost {
            font-weight: 700 !important;
            font-size: 16px !important;
            line-height: 24px !important;
            text-align: center !important;
            color: #003A63 !important;
            margin-right: 24px !important;

            &.black {
                color: #000 !important;
            }
        }
    }

    .color-red {
        color: #D10A0F;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
    }

    .del-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #FFDBDB;
        border-radius: 40px;
        margin-right: 16px;
    }

    .notification-success {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #6F727A;
        padding-bottom: 40px;

        .icon {
            background: #249F5D;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .caption {
            margin-top: 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #333333;
            margin-bottom: 16px;
        }
    }

    .v-autocomplete {
        padding-top: 0 !important;
    }

    .v-picker {
        .v-btn {
            width: 25px;
            max-width: 25px;
            min-width: 25px;
            height: 28px;
            max-height: 28px;
            min-height: 28px;
            border-radius: 4px;
            letter-spacing: 0;
            border-radius: 4px !important;
            font-style: normal;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 24px !important;

            &--active {
                background: #003A63;
                border: 1px solid #003A63;
                font-weight: 700 !important;
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0 !important;
            }
        }

        .v-picker__title {
            display: none !important;
        }
    }

    .v-date-picker-table {
        padding: 0 !important;
    }

    .v-picker__body {
        max-width: 100% !important;
    }

    .v-date-picker-header {
        padding: 4px 0;
    }

    .v-date-picker-table {
        height: auto !important;
    }

    .row-btn {
        display: flex;
        align-items: center;

        button {
            background: #F7F7F9;
            border-radius: 16px;
            padding: 2px 12px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #374151;
            transition: all .3s;

            &:hover,
            &.active {
                background: #DCECFB;
                color: #003A63;
            }


            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .v-input__append-inner {
        height: 48px;
        display: flex;
        align-items: center;
        margin-top: 0;
    }

    .select-time {
        .label {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #6F727A;
            margin-bottom: 8px;
        }

        .inline {
            display: grid;
            grid-template-columns: 1fr 52px;
            gap: 8px;

            .my-select {}

            .ghost.border {
                /* Auto layout */
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 16px;
                gap: 10px;
                width: 52px;
                height: 48px;
                background: #FFFFFF;
                border: 1px solid #809CB1 !important;
                border-radius: 8px;
            }
        }
    }

    .time-list {

        margin-top: 24px;
        padding: 0;
        list-style: none;

        li {
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .tour_date {
        cursor: pointer;
        transform: all;

        &:hover {
            color: red;
        }
    }
}
</style>

<template>
  <div id="order-card-page">
    <Grid column="1" :gap="24">
      <Grid column="2" gap="32" :style="{ gridColumn: '1/3', gridTemplateColumns: '122px 1fr', alignItems: 'center' }">
        <v-btn class="button go-back" variant="tonal" color="primary" block @click="$router.back()">
          <Icon width="20" height="20" viewBox="0 0 22 22" strockeWidth="2" iconName="arrow-left" />
          Назад
        </v-btn>
        <div class="caption-block">
          <h4>Заказ #{{ formData.booking_code }}</h4>
          <div class="breadcrumbs">
            <router-link to="/orders">Заказы</router-link>
            <Icon width="20" height="20" viewBox="0 0 18 18" strockeWidth="0" :iconName="'chevron-right'"
              fill="#333333" />

            <span>Заказ #{{ formData.booking_code }}</span>
          </div>
        </div>
      </Grid>

      <Block :custom-style="{ overflow: 'hidden' }">
        <div class="grid-block tabs">
          <ul>
            <li v-for="tab of tabs" :key="tab" :class="{ active: tab.id == activeTab }" @click="activeTab = tab.id">{{
              tab.name
            }}
            </li>
          </ul>
        </div>
        <TableBlock v-if="activeTab == 2">
          <template v-slot:head>
            <tr>
              <td>Количество</td>
              <td>Взрослых</td>
              <td>Детских 7-11 лет</td>
              <td>Детских 3-6 лет</td>
              <td>Сумма</td>
            </tr>
          </template>
          <template v-slot:body>
            <tr>
              <td>{{ countTickets }}</td>
              <td>{{ formData.adults }}</td>
              <td>{{ formData.child }}</td>
              <td>{{ formData.babies }}</td>
              <td>{{ formData.price }}$</td>
            </tr>
          </template>
        </TableBlock>
        <Grid column="3" gap="16" :style="{ padding: '20px' }" v-if="activeTab == 3">
          <div class="item-block">
            <div class="label">Имя</div>
            <v-text-field v-model="clientData.firstname" autocomplete="off" outlined class="input"></v-text-field>
            <div class="label">Email</div>
            <v-text-field v-model="clientData.email" autocomplete="off" outlined class="input"></v-text-field>
            <div class="label">Местоположение клиента</div>
            <v-textarea placeholder="Введите описание" v-model="formData.address" autocomplete="off" outlined
              class="input"></v-textarea>
          </div>
          <div class="item-block">
            <div class="label">Фамилия</div>
            <v-text-field v-model="clientData.lastname" autocomplete="off" outlined class="input"></v-text-field>
          </div>
          <div class="item-block">
            <div class="label">Телефон</div>
            <v-text-field v-model="clientData.phone" autocomplete="off" outlined class="input"></v-text-field>
          </div>
        </Grid>
        <Grid column="2" gap="1" :style="{ background: '#809CB1', rowGap: '1px' }" v-if="activeTab == 1">
          <div class="grid-block">
            <div class="img-slider">
              <transition-group name="list">
                <template v-for="(img, ind) in formData.images">
                  <img :src="mediaServerUrl + img" :key="img" v-show="activeSlide == ind">
                </template>
              </transition-group>
              <div class="img-slider__buttons">
                <button @click="changeSlide('prev')">
                  <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0" :iconName="'chevron-left'"
                    fill="#6F727A" />
                </button>
                <button @click="changeSlide('next')">
                  <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0" :iconName="'chevron-right'"
                    fill="#6F727A" />
                </button>
              </div>
            </div>
          </div>
          <div class="grid-block">
            <Grid column="2" :style="{ gridTemplateColumns: '258px 1fr', gap: '40px' }">
              <Block padding="20">
                <div class="row-btn">
                  <button :class="{ 'active': tourDate.date == $moment().format('YYYY-MM-DD') }"
                    @click="tourDate.date = $moment().format('YYYY-MM-DD')">
                    Сегодня
                  </button>
                  <button :class="{ 'active': tourDate.date == $moment().add(1, 'day').format('YYYY-MM-DD') }"
                    @click="tourDate.date = $moment().add(1, 'day').format('YYYY-MM-DD')">
                    Завтра
                  </button>
                </div>
                <v-date-picker v-model="tourDate.date" :first-day-of-week="1" locale="ru-RU"
                  :weekday-format="getCustomDay" :min="$moment().format('YYYY-MM-DD')" range></v-date-picker>
              </Block>
              <div class="select-time">
                <div class="label">Время</div>
                <div class="inline" v-for="(time, ind) of tourDate.time" :key="'t-' + time">
                  <SelectBlock v-model="tourDate.time[ind]" :items="timeList"></SelectBlock>
                  <button class="ghost border" @click="addTime()" v-if="ind == tourDate.time.length - 1">
                    <Icon width="20" height="20" viewBox="0 0 15 15" strockeWidth="1.5" :iconName="'plus'" />
                  </button>
                </div>
                <ul class="time-list">
                  <li>{{ formatedTourDate }}</li>
                  <!--                            <li v-for="time of tourDate.time" :key="'t-'+time">{{ time }}</li>-->
                </ul>
              </div>
            </Grid>
          </div>
          <div class="grid-block">
            <h4>{{ formData.title[language] }}</h4>
            <div class="descr">{{ formData.descr[language] }}</div>
            <div class="cap">О мероприятии</div>
            <div class="list-info">
              <div class="list-info__item">
                <span>Тип</span>{{ tourType[formData.tour_type] }}
              </div>
              <div class="list-info__item">
                <span>Длительность</span>{{ duration('' + formData.duration) }}
              </div>
              <div class="list-info__item">
                <span>Численность</span>{{
                  formData.max_people + ' ' + declOfNum(formData.max_people, ['человек', 'человека', 'человек'])
                }}
              </div>
              <div class="list-info__item">
                <span>Язык</span>{{ languagesList[formData.language_id] }}
              </div>
              <div class="list-info__item">
                <span>Питание</span>Включено
              </div>
            </div>
            <div class="cap">Стоимость</div>

            <div class="list-info">
              <div class="list-info__item">
                <span>Взрослый</span>{{ formData.adult_price }}
              </div>
              <div class="list-info__item">
                <span>Дети 7-11 лет</span>{{ formData.child_price }}
              </div>
            </div>
            <div class="cap">Подробнее</div>
            {{ formData.notes }}
          </div>
          <div class="grid-block">
            <div class="faq">
              <ul>
                <li v-for="(item, ind) of formData.faq" :key="'q-' + ind">
                  <h5>{{ item.question }}</h5>
                  {{ item.answer }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-button">
            <v-btn type="submit" class="button mt-6" variant="tonal" color="primary">
              Изменить
            </v-btn>
          </div>
        </Grid>
      </Block>
    </Grid>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import Block from "@/components/Block.vue";


export default {
  name: 'ExcursionPage',
  components: { Block },
  mixins: [globalMethods],
  computed: {
    ...mapState({
      mediaServerUrl: state => state.mediaServerUrl,
      language: state => state.language,
    }),
    countTickets() {
      return parseInt(this.formData.adults) + parseInt(this.formData.child) + parseInt(this.formData.babies)
    },
    formatedTourDate() {
      if (this.tourDate.date) {
        if (Array.isArray(this.tourDate.date)) {
          return this.$moment(this.tourDate.date[0], 'YYYY-MM-DD').format('DD.MM.YYYY') + ' — ' + this.$moment(this.tourDate.date[1], 'YYYY-MM-DD').format('DD.MM.YYYY')
        } else {
          return this.$moment(this.tourDate.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
        }
      } else {
        return ''
      }
    },
  },
  data() {
    return {
      clientData: {
        name: "Иван",
        surname: "Иванов",
        phone: "",
        email: "",
        place: "",
      },
      tabs: [
        { id: 1, name: 'Экскурсия' },
        { id: 2, name: 'Состав заказа' },
        { id: 3, name: 'Данные клиента' },
      ],
      activeTab: 1,
      timeList: [
        {
          label: '09:00',
          value: '09:00',
        },
        {
          label: '10:00',
          value: '10:00',
        },
        {
          label: '11:00',
          value: '11:00',
        },
        {
          label: '12:00',
          value: '12:00',
        },
        {
          label: '13:00',
          value: '13:00',
        },
        {
          label: '14:00',
          value: '14:00',
        },
        {
          label: '15:00',
          value: '15:00',
        },
        {
          label: '16:00',
          value: '16:00',
        },
        {
          label: '17:00',
          value: '17:00',
        },
        {
          label: '18:00',
          value: '18:00',
        },
        {
          label: '19:00',
          value: '19:00',
        },
        {
          label: '20:00',
          value: '20:00',
        },
        {
          label: '21:00',
          value: '21:00',
        },
        {
          label: '22:00',
          value: '22:00',
        },
      ],
      tourDate: {
        date: null,
        time: ['09:00']
      },
      activeSlide: 0,
      isChecked: false,
      answer: '',
      query: '',
      errors: {
        title: '',
        descr: '',
        child_price: '',
        adult_price: '',
        notes: '',
      },
      tourType: {},
      transportTypes: {},
      cities: {},
      languagesList: {},
      id: null,
      isDragging: false,
      formData: {
        city_id: "efa4b22f384bf4ebb8055603a6ca60fe",
        tour_type: "599eac533714c4c0bba4d84ed70506a5",
        transport_type: "44d4126baecd130544f05182d10b2b10",
        title: { "RU": "Анталия. Теплицы", "EN": "Antaliya. Waterfall" },
        descr: { "RU": "Пешая экскурсия вдоль канала", "EN": "Pedestrian tour through the watercourse" },
        max_people: 10,
        duration: 6,
        language_id: "0c7ebcbcca08de4c2e84665ccb207a16",
        meal_type_id: "3d39c99fb5ea69c00cbd85d1a2499ba8",
        main_image: "e54ddb555a67f4432f8581a33efb7c80.jpg",
        images: ["e54ddb555a67f4432f8581a33efb7c80.jpg", "94d39c0c06334a13b34e7d22038cef40.jpg"],
        adult_price: 12,
        child_price: 6,
        old_adult_price: 16,
        old_child_price: 4,
        faq: [{ question: "Плавки брать?", answer: "Да" }, {
          question: "Котики будут?",
          answer: "Нет, только черепашки"
        }],
        notes: "Оденьте спортивную обувь",
        individual: true
      }
    }
  },
  mounted() {
    if (this.$route.params.orderId) {
      this.$set(this, 'id', this.$route.params.orderId)

      this.sendRequest('GET', { id: this.$route.params.orderId }, '/getBooking', {}, true)
        .then(response => {
          let formData = response
          Promise.all([
            this.sendRequest('GET', { page: 1, size: 1, id: formData.tour_id }, '/getTours', {}, true),
            this.sendRequest('GET', { id: formData.user_id }, '/getProfile', {}, true)
          ]).then(([tour, user]) => {

            let data = tour
            try {
              data.title = JSON.parse(data.title)
              data.descr = JSON.parse(data.descr)
            } catch (err) {
              console.error(err)
            }
            this.$set(this, 'formData', { ...formData, ...data })
            this.$set(this, 'clientData', user)

          })
            .catch(err => {
              this.$router.push('/excursions')
              this.$store.commit('setError', err)
            })
          // if (response[0]) {
          //   let data = response[0]
          //   try {
          //     data.title = JSON.parse(data.title)
          //     data.descr = JSON.parse(data.descr)
          //   } catch (err) {
          //     console.error(err)
          //   }
          //   this.$set(this, 'formData', data)
          // }
        })
        .catch(err => {
          this.$store.commit('setError', err)
        })
    } else {
      return this.$router.push('/orders')
    }
    Promise.all([
      this.loadGuide('tourType', '/getTourTypes'),
      this.loadGuide('transportTypes', '/getTransportTypes'),
      this.loadGuide('cities', '/getCities'),
      this.loadGuide('languagesList', '/getLanguages'),
    ]).then(([tourType, transportTypes, cities, languagesList]) => {
      this.$set(this, 'tourType', tourType)
      this.$set(this, 'transportTypes', transportTypes)
      this.$set(this, 'cities', cities)
      this.$set(this, 'languagesList', languagesList)
    })
    // this.sendRequest('GET', {}, '/getTransportTypes', {}, true)
    //     .then(response => {
    //         this.$set(this, 'goodsList', response)
    //     })
    //     .catch(err => {
    //         this.$store.commit('setError', err)
    //     })
  },
  methods: {
    changeSlide(state) {
      if (state == 'prev') {
        if (this.activeSlide <= 0) {
          this.$set(this, 'activeSlide', this.formData?.images?.length - 1)
        } else {
          this.$set(this, 'activeSlide', this.activeSlide - 1)
        }
      } else {
        if (this.activeSlide >= this.formData?.images?.length - 1) {
          this.$set(this, 'activeSlide', 0)
        } else {
          this.$set(this, 'activeSlide', this.activeSlide + 1)
        }
      }
    },
    getCustomDay(date) {
      const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
      let i = new Date(date).getDay(date)
      return daysOfWeek[i]
    },
    duration(d) {
      let [hour, minutes] = '' + d.split('.')
      if (!minutes) {
        minutes = 0
      }
      return `${hour} ${this.declOfNum(hour, ['час', 'часа', 'часов'])} ${minutes} ${this.declOfNum(minutes, ['минута', 'минут', 'минут'])}`
    },
  }
}
</script>
<style lang="scss">
#order-card-page {
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #6F727A;
    margin-bottom: 8px;
  }

  .images-block {
    .dropzone {
      height: 264px;
      border: 1px dashed #809CB1;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 32px;

      svg {
        margin-bottom: 10px;
      }

      p {
        &:nth-child(2) {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;

          span {
            color: #003A63;
            cursor: pointer;
          }
        }

        &:last-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #6F727A;
        }
      }
    }
  }

  .column-2 {
    grid-column: 1/3;
  }

  .faq {
    .inline {
      display: grid;
      grid-template-columns: 1fr 52px;
      gap: 8px;
      margin-bottom: 12px;

      .my-select {}

      .ghost.border {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        gap: 10px;
        width: 52px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #809CB1 !important;
        border-radius: 8px;
      }
    }

  }


  .form-button {
    background: #fff;
    grid-column: 1/3;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    margin-top: -1px;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s;
  }

  .list-enter,
  .list-leave-to

  /* .list-leave-active below version 2.1.8 */
    {
    opacity: 0;
    position: absolute;
    transform: translateY(30px);
  }

  .row-btn {
    display: flex;
    align-items: center;

    button {
      background: #F7F7F9;
      border-radius: 16px;
      padding: 2px 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #374151;
      transition: all .3s;

      &:hover,
      &.active {
        background: #DCECFB;
        color: #003A63;
      }


      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .v-input__append-inner {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0;
  }

  .select-time {
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #6F727A;
      margin-bottom: 8px;
    }

    .inline {
      display: grid;
      grid-template-columns: 1fr 52px;
      gap: 8px;

      .my-select {}

      .ghost.border {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        gap: 10px;
        width: 52px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #809CB1 !important;
        border-radius: 8px;
      }
    }
  }

  .time-list {

    margin-top: 24px;
    padding: 0;
    list-style: none;

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  .v-picker {
    .v-btn {
      width: 25px;
      max-width: 25px;
      min-width: 25px;
      height: 28px;
      max-height: 28px;
      min-height: 28px;
      border-radius: 4px;
      letter-spacing: 0;
      border-radius: 4px !important;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 24px !important;

      &--active {
        background: #003A63;
        border: 1px solid #003A63;
        font-weight: 700 !important;
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 !important;
      }
    }

    .v-picker__title {
      display: none !important;
    }
  }

  .v-date-picker-table {
    padding: 0 !important;
  }

  .v-picker__body {
    max-width: 100% !important;
  }

  .v-date-picker-header {
    padding: 4px 0;
  }

  .v-date-picker-table {
    height: auto !important;
  }

  .grid-block {
    background: #fff;
    padding: 20px;

    &.tabs {
      background: #F7F7F9;

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 10px 16px;
          background: #FFFFFF;
          border-radius: 8px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #6F727A;
          cursor: pointer;

          &.active {
            background: #DCECFB;
            color: #003A63;
          }

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #333333;
      margin-bottom: 16px;
    }

    .descr {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .cap {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .list-info {
      margin-bottom: 20px;

      &__item {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;

        span {
          display: block;
          width: 156px;
          color: #999999;

        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  .faq {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        h5 {
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          color: #333333;
          margin-bottom: 16px;
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .img-slider {
    width: 100%;
    height: 467px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    &__buttons {
      position: absolute;
      left: 0;
      bottom: 15px;
      right: 0;
      width: 100px;
      border-radius: 8px;
      display: grid;
      overflow: hidden;
      grid-template-columns: repeat(2, 1fr);
      gap: 1px;
      background: #809CB1;
      border: 1px solid #809CB1;
      margin: auto;

      button {
        width: 100%;
        height: 50px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  table {
    thead {
      td {
        padding: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        color: #809CB1;
      }
    }
  }
}
</style>

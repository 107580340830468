<template>
    <div id="administrations-page">
        <Grid column="2" :style="gridStyle">
            <div class="caption-block">
                <h4>Администраторы</h4>
                <span>Администраторы</span>
            </div>
            <v-btn class="button mt-6" variant="tonal" color="primary" block @click="addAdmin()">
                Добавить администратора
            </v-btn>
            <Block :style="{ gridColumn: '1/3', padding: '0', overflow: 'hidden' }">
                <TableBlock>
                    <template v-slot:head>
                        <tr>
                            <th class="w-85">
                                <span>#</span>
                                <v-text-field v-model="filter.number" outlined class="input"
                                    placeholder="Все"></v-text-field>
                            </th>
                            <th class="name">
                                <span>Имя</span>
                                <v-text-field v-model="filter.name" outlined class="input" placeholder="Все"></v-text-field>
                            </th>
                            <th class="phone">
                                <span>Телефон</span>
                                <v-text-field v-model="filter.phone" outlined class="input"
                                    placeholder="Все"></v-text-field>
                            </th>
                            <th class="w-150">
                                <span>Тип</span>
                                <SelectBlock v-model="filter.user_type" :items="types"></SelectBlock>
                            </th>
                            <th class="w-150">
                                <span>Статус</span>
                                <SelectBlock v-model="filter.state" :items="states"></SelectBlock>
                            </th>
                            <th></th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="(admin, ind) of admins" :key="'admin-' + admin.id">
                            <td class="w-85">{{ ind + 1 }}</td>
                            <td class="name">{{ admin.name }}</td>
                            <td class="phone">{{ formattedPhoneNumber(admin.phone) }}</td>
                            <td class="w-150">{{ userType?.[admin.user_type] || '—' }}</td>
                            <td class="w-150">{{ status?.[admin.state] || '—' }}</td>
                            <td>
                                <button class="ghost edit-btn" @click="editAdmin(admin)">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'edit'"
                                        stroke="#003A63" />
                                </button>
                                <button class="ghost delete-btn" @click="deleteAdmin(admin.id)">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'trash'"
                                        stroke="#D10A0F" />
                                </button>
                            </td>
                        </tr>
                    </template>
                </TableBlock>
                <div class="table-control">
                    <div class="count-items">
                        <span>Строк на странице:</span>
                        <SelectBlock v-model="filter.size" :items="countItems"></SelectBlock>
                    </div>
                    <div class="pagination">
                        <ul>
                            <li>
                                <button class="ghost" @click="changePage('prev')">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0"
                                        :iconName="'chevron-left'" fill="#6F727A" />
                                </button>
                            </li>
                            <li :class="{ actvie: filter.page == '' + showListNow }">1</li>
                            <li :class="{ actvie: filter.page == '' + showListNow }">{{ +filter.page + 1 }}</li>
                            <li>...</li>
                            <li :class="{ actvie: filter.page == showListNow }">{{ countList }}</li>
                            <li>
                                <button class="ghost" @click="changePage('next')">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0"
                                        :iconName="'chevron-right'" fill="#6F727A" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Block>
        </Grid>
        <Modal ref="add-admin" :notFooterBorder="true" padding="40">
            <template v-slot:header>
                <template v-if="adminEdit">
                    Редактировать администратора
                </template>
                <template v-else>
                    Добавить администратора
                </template>
            </template>
            <template v-slot:subheader>
                Все поля обязательные
            </template>
            <template v-slot:body>
                <v-form @submit.prevent="false" class="new-admin-form">
                    <label>
                        <span>Имя</span>
                        <v-text-field v-model="newAdmin.name" autocomplete="off" outlined class="input" placeholder=""
                            :error-messages="errors.name" :error="!!errors.name"></v-text-field>
                    </label>
                    <label>
                        <span>Телефон</span>
                        <v-text-field v-model="newAdmin.phone" autocomplete="off" outlined class="input"
                            placeholder="Номер телефона" :error-messages="errors.phone"
                            :error="!!errors.phone"></v-text-field>
                    </label>
                    <div class="row-select">
                        <label>
                            <span>Тип</span>
                            <SelectBlock v-model="newAdmin.user_type" :items="types"></SelectBlock>
                        </label>
                        <label>
                            <span>Статус</span>
                            <SelectBlock v-model="newAdmin.state" :items="states"></SelectBlock>
                        </label>
                    </div>

                </v-form>
            </template>
            <template v-slot:footer>
                <div class="button-block">
                    <button class="ghost" @click="$refs['add-admin']._cancel()">
                        Отменить
                    </button>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="createAdmin()">
                        Сохранить
                    </v-btn>
                </div>
            </template>
        </Modal>
        <Modal ref="delete-admin" :notFooterBorder="true" padding="40">
            <template v-slot:header>
                <div class="del-icon">
                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'trash'"
                        stroke="#D10A0F" />
                </div>
                Удалить администатора
            </template>
            <template v-slot:body>
                Вы действительно хотите удалить администатора
                <div class="color-red">Это действие является постоянным и не может быть отменено!</div>
            </template>
            <template v-slot:footer>
                <div class="button-block">
                    <button class="ghost black" @click="$refs['delete-admin']._cancel()">
                        Отмена
                    </button>
                    <v-btn type="submit" class="button mt-6 error" variant="tonal" color="primary"
                        @click="deleteAdminConfirm()">
                        Удалить
                    </v-btn>
                </div>
            </template>
        </Modal>
        <Modal ref="notification" :notFooterBorder="true" padding="40" :hiddeCloseBtn="true" :hiddeHeader="true"
            :hiddeFooter="true">
            <template v-slot:body>
                <div class="notification-success">
                    <div class="icon">
                        <Icon width="20" height="20" viewBox="0 0 15 15" strockeWidth="3.5" :iconName="'check'"
                            stroke="#fff" />
                    </div>
                    <div class="caption">
                        Администратор добавлен
                    </div>
                    <p><b>Пароль</b> — <b>{{ newPassw }}</b></p>
                    <p>Новый администратор может войти по номеру указанному в анкете</p>
                </div>
                <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" block
                    @click="$refs['notification']._confirm()">
                    Продолжить
                </v-btn>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { globalMethods } from '@/mixins/globalMethods'

export default {
    name: 'AdministrationsPage',
    mixins: [globalMethods],
    data() {
        return {
            newPassw: '',
            adminEdit: false,
            errors: {
                name: '',
                phone: '',
            },
            showListNow: 1,
            countList: 8,
            countItems: [
                {
                    value: '10',
                    label: '10',
                },
                {
                    value: '30',
                    label: '30',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '100',
                    label: '100',
                },
            ],
            types: [
                {
                    value: '',
                    label: 'Все',
                },
                {
                    value: 'superadmin',
                    label: 'Суперадмин',
                },
                {
                    value: 'admin',
                    label: 'Админ',
                },
                {
                    value: 'operator',
                    label: 'Оператор',
                },
            ],
            states: [
                {
                    value: '',
                    label: 'Все',
                },
                {
                    value: 'active',
                    label: 'Активный',
                },
                {
                    value: 'deactive',
                    label: 'Неактивный',
                },
            ],
            userType: {
                'admin': 'Админ',
                'superadmin': 'Суперадмин',
                'operator': 'Оператор',
            },
            status: {
                'active': 'Активный',
                'deactive': 'Неактивный',
            },
            gridStyle: { gridTemplateColumns: '1fr 256px', rowGap: '24px' },//, gridTemplateRows: '78px 1fr', height: '100vh'
            admins: [],
            filter: {
                number: '',
                name: '',
                phone: '',
                user_type: '',
                state: '',
                page: '1',
                size: '10',
            },
            newAdmin: {
                name: '',
                phone: '',
                user_type: 'admin',
                state: 'active',
            },
            deleteId: null,
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
        this.filterRequest = debounce(this.filterRequest, 500)
        this.sendRequest('GET', { page: 1, size: this.filter.size }, '/getAdmins', {}, true)
            .then(response => {
                this.$set(this, 'admins', response)
            })
            .catch(err => {
                this.$store.commit('setError', err)
            })
    },
    watch: {
        filter: {
            deep: true,
            handler(newVal, oldVal) {
                this.filterRequest()
            },
        }
    },
    methods: {
        addAdmin() {
            this.$set(this, 'adminEdit', false)
            this.showModal('add-admin')
        },
        deleteAdminConfirm() {
            this.sendRequest('DELETE', { id: this.deleteId }, '/removeAdmin', {}, true)
                .then(response => {
                    let findIndex = this.admins.findIndex(a => a.id == this.deleteId)
                    if (findIndex) {
                        this.$delete(this.admins, findIndex)
                    }
                    this.$set(this, 'deleteId', null)
                    this.$refs['delete-admin']._confirm()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        deleteAdmin(id) {
            this.$set(this, 'deleteId', id);
            this.showModal('delete-admin')
        },
        editAdmin(admin) {

            this.$set(this, 'adminEdit', true)
            this.$set(this, 'newAdmin', { ...admin, type: 'admin' })
            this.showModal('add-admin')
        },
        createAdmin() {
            this.checkPhone(this.newAdmin)
                .then(this.checkName)
                .then(data => {
                    this.sendRequest('POST', data, '/addAdmin', {}, true)
                        .then(response => {
                            if (data.id) {
                                let index = this.admins.findIndex(a => a.id == data.id)
                                this.$set(this.admins, index, data)
                            } else {
                                let newAdmins = [...this.admins, response]
                                this.$set(this, 'admins', newAdmins)
                                this.$set(this, 'newPassw', response.passw)
                                this.showModal('notification')
                            }
                            this.$set(this, 'newAdmin', {
                                name: "",
                                phone: "",
                                user_type: "admin",
                                state: "active"
                            })
                            this.$refs['add-admin']._confirm()
                        })
                        .catch(err => {
                            this.$store.commit('setError', err)
                        })
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })

            this.$refs['add-admin']._confirm()
        },
        changePage(state) {
            if (state === 'prev') {
                if (this.filter.page > 1) {
                    this.$set(this.filter, 'page', parseInt(this.filter.page) - 1)
                    this.$set(this, 'showListNow', parseInt(this.filter.page) - 1)
                }
            } else if (state === 'next') {
                if (this.filter.page < this.countList) {
                    this.$set(this.filter, 'page', parseInt(this.filter.page) + 1)
                    this.$set(this, 'showListNow', parseInt(this.filter.page) + 1)
                }
            }
        },
        filterRequest() {
            this.sendRequest('GET', this.filter, '/getAdmins', {}, true)
                .then(response => {
                    this.$set(this, 'admins', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)

                })
        }
    }
}
</script>
<style lang="scss">
#administrations-page {
    table {

        th,
        td {
            &.w-85 {
                width: 85px;
            }

            &.name {
                width: 2fr;
            }

            &.phone {
                width: 1fr;
            }

            &.w-150 {
                width: 150px;
            }

            &:last-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }

    .table-control {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px;
        border-top: 1px solid #809CB1;

        .count-items {
            display: flex;
            align-items: center;
            margin-right: 48px;

            >span {
                display: block;
                margin-right: 16px;
            }

            .my-select {
                width: 70px;
            }
        }

        .pagination {
            ul {
                display: flex;
                align-items: center;
                border: 1px solid #809CB1;
                border-radius: 4px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    width: 42px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: #809CB1;

                    &.active {
                        color: #333333;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid #809CB1;
                    }
                }
            }

        }
    }

    .edit-btn {
        margin-right: 15px !important;
        padding-right: 15px !important;
        position: relative;

        &:after {
            content: "";
            width: 1px;
            height: 20px;
            background: #D9D9D9;
            position: absolute;
            right: 0px;
            display: block;
        }
    }

    .new-admin-form {
        label {
            display: block;
            margin-bottom: 40px;

            >span {
                display: block;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #6F727A;
                margin-bottom: 8px;
            }
        }

        .row-select {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;

            label {
                margin-bottom: 0px;
            }
        }
    }

    .button-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ghost {
            font-weight: 700 !important;
            font-size: 16px !important;
            line-height: 24px !important;
            text-align: center !important;
            color: #003A63 !important;
            margin-right: 24px !important;

            &.black {
                color: #000 !important;
            }
        }
    }

    .color-red {
        font-style: italic;
        color: #D10A0F;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
    }

    .del-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #FFDBDB;
        border-radius: 40px;
        margin-right: 16px;
    }

    .notification-success {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #6F727A;
        padding-bottom: 40px;

        .icon {
            background: #249F5D;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .caption {
            margin-top: 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #333333;
            margin-bottom: 16px;
        }
    }
}
</style>

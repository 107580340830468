import Vue from 'vue'
import App from './App.vue'
import DefaultLayout from "./layouts/default"
import LoginLayout from "./layouts/login"
import moment from 'moment';
import router from './router'
import store from './store'
import DesignElements from './plugins/import-design-elements.js'
import vuetify from './plugins/vuetify'
import VueCookies from './plugins/vue-cookies'
import VueSlideUpDown from './plugins/vue-slide-up-down'
import { VueMaskDirective } from 'v-mask'
import VueApexCharts from 'vue-apexcharts';

import 'normalize.css/normalize.css'
import './style.scss'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

Vue.prototype.$moment = moment;

Vue.component('apexchart', VueApexCharts);
Vue.component("default-layout", DefaultLayout)
Vue.component("login-layout", LoginLayout)
Vue.directive('mask', VueMaskDirective);

new Vue({
  router,
  store,
  vuetify,
  DesignElements,
  VueCookies,
  VueSlideUpDown,
  render: h => h(App)
}).$mount('#app')

<template>
    <div class="monitoring-page">
        <Grid column="1">
            <div class="caption-block">
                <h4>Мониторинг</h4>
                <span>Мониторинг</span>
            </div>
            <Block :customStyle="{ border: 'unset' }">
                <Grid column="6" gap="16">
                    <v-btn class="button mt-6" variant="tonal" :color="statPeriod == 'today' ? 'primary' : null"
                        @click="statPeriod = 'today'">
                        Сегодня
                    </v-btn>
                    <v-btn class="button mt-6" variant="tonal" :color="statPeriod == 'yesterday' ? 'primary' : null"
                        @click="statPeriod = 'yesterday'">
                        Вчера
                    </v-btn>
                    <v-btn class="button mt-6" variant="tonal" :color="statPeriod == 'week' ? 'primary' : null"
                        @click="statPeriod = 'week'">
                        Неделя
                    </v-btn>
                    <v-btn class="button mt-6" variant="tonal" :color="statPeriod == 'month' ? 'primary' : null"
                        @click="statPeriod = 'month'">
                        Месяц
                    </v-btn>
                    <v-btn class="button mt-6" variant="tonal" :color="statPeriod == 'quarter' ? 'primary' : null"
                        @click="statPeriod = 'quarter'">
                        Квартал
                    </v-btn>
                    <v-btn class="button mt-6" variant="tonal" :color="statPeriod == 'year' ? 'primary' : null"
                        @click="statPeriod = 'year'">
                        Год
                    </v-btn>
                </Grid>
            </Block>
            <Block :customStyle="{ border: 'unset' }">
                <template v-if="statEventLoading">
                    Получаем данные. Это может занять несколько минут
                </template>
                <template v-else>
                    <template v-if="statEvent.length > 0">
                        <apexchart :options="chartOptions" :series="chartSeries" type="line" />

                    </template>
                    <template v-else>
                        Данных нет
                    </template>
                </template>
            </Block>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'MonitoringPage',
    mixins: [globalMethods],
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: []
                }
            },
            chartSeries: [],
            statPeriod: 'today',
            gridStyle: {

            },
            login: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
            },
            password: '',
            remember: false,
            value: String,
            dialog: false,
            statEvent: [],
            statEventLoading: true,
            gradient: null
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
        this.sendRequest('GET', { period: this.statPeriod }, '/metrics', {}, false, '/stats')
            .then(response => {
                this.$set(this, 'statEvent', response.totals[0])
                this.renderChart(response.totals[0], response.time_intervals)
            })
            .catch(err => {
                this.$set(this, 'statEventLoading', false)
                this.$store.commit('setError', err)
            })
    },
    watch: {
        statPeriod: function (val) {
            this.$set(this, 'statEventLoading', true)
            this.sendRequest('GET', { period: val }, '/metrics', {}, false, '/stats')
                .then(response => {
                    this.$set(this, 'statEvent', response.totals[0])
                    this.renderChart(response.totals[0], response.time_intervals)
                })
                .catch(err => {
                    this.$set(this, 'statEventLoading', false)
                    this.$store.commit('setError', err)
                })
        }
    },
    methods: {
        renderChart(dataStat, interval) {
            // if (!Array.isArray(dataStat)) {
            //     return false
            // }

            let labels = [],
                datasets = [],
                datalabels = []

            // if (this.statPeriod == 'today') {
            //     labels.push(this.$moment().format('DD.MM.YYYY'));
            //     let events = {}
            //     dataStat.forEach(element => {
            //         if (!events[element.event_name]) {
            //             events[element.event_name] = 1
            //         } else {
            //             ++events[element.event_name]
            //         }
            //     });
            //     Object.entries(events).forEach(([name, value]) => {
            //         datasets.push({
            //             name: name,
            //             data: [value]
            //         })
            //     })
            // } else if (this.statPeriod == 'yesterday') {
            //     labels.push(this.$moment().add(-1, 'day').format('DD.MM.YYYY'));
            //     let events = {}
            //     dataStat.forEach(element => {
            //         if (!events[element.event_name]) {
            //             events[element.event_name] = 1
            //         } else {
            //             ++events[element.event_name]
            //         }
            //     });
            //     Object.entries(events).forEach(([name, value]) => {
            //         datasets.push({
            //             name: name,
            //             data: [value]
            //         })
            //     })
            // } else if (this.statPeriod == 'week') {
            //     const currentDate = this.$moment();
            //     const startOfQuarter = this.$moment().startOf('week');
            //     const endOfQuarter = this.$moment().endOf('week');

            //     let currentMonth = startOfQuarter.clone();
            //     while (currentMonth.isSameOrBefore(endOfQuarter, 'day')) {
            //         labels.push(currentMonth.format('DD.MM.YYYY'));
            //         currentMonth.add(1, 'day');
            //     }

            //     let events = {}
            //     dataStat.forEach(element => {
            //         let date = this.$moment(element.event_datetime, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')

            //         if (!events[element.event_name]) {
            //             events[element.event_name] = {}
            //         }
            //         if (!events[element.event_name][date]) {
            //             events[element.event_name][date] = 1
            //         } else {
            //             ++events[element.event_name][date]
            //         }
            //     });

            //     Object.entries(events).forEach(([name, stats]) => {
            //         let data = []
            //         labels.forEach((month) => {
            //             let count = stats?.[month] || 0
            //             data.push(count)
            //         })

            //         datasets.push({
            //             name: name,
            //             data: data
            //         })
            //     })
            // } else if (this.statPeriod == 'month') {
            //     const currentDate = moment();
            //     const startOfQuarter = moment().startOf('month');
            //     const endOfQuarter = moment().endOf('month');

            //     let currentMonth = startOfQuarter.clone();
            //     while (currentMonth.isSameOrBefore(endOfQuarter, 'day')) {
            //         labels.push(currentMonth.format('DD.MM.YYYY'));
            //         currentMonth.add(1, 'day');
            //     }

            //     let events = {}
            //     dataStat.forEach(element => {
            //         let date = this.$moment(element.event_datetime, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')

            //         if (!events[element.event_name]) {
            //             events[element.event_name] = {}
            //         }
            //         if (!events[element.event_name][date]) {
            //             events[element.event_name][date] = 1
            //         } else {
            //             ++events[element.event_name][date]
            //         }
            //     });

            //     Object.entries(events).forEach(([name, stats]) => {
            //         let data = []
            //         labels.forEach((month) => {
            //             let count = stats?.[month] || 0
            //             data.push(count)
            //         })

            //         datasets.push({
            //             name: name,
            //             data: data
            //         })
            //     })


            // } else if (this.statPeriod == 'quarter') {
            //     // Получение текущей даты
            //     const currentDate = moment();
            //     const startOfQuarter = moment().startOf('quarter');
            //     const endOfQuarter = moment().endOf('quarter');

            //     // Получение массива месяцев за текущий квартал
            //     const monthsInQuarter = [];
            //     let currentMonth = startOfQuarter.clone();
            //     while (currentMonth.isSameOrBefore(endOfQuarter, 'month')) {
            //         labels.push(currentMonth.format('MM.YYYY'));
            //         currentMonth.add(1, 'month');
            //     }

            //     let events = {}
            //     dataStat.forEach(element => {
            //         let date = this.$moment(element.event_datetime, 'YYYY-MM-DD HH:mm:ss').format('MM.YYYY')

            //         if (!events[element.event_name]) {
            //             events[element.event_name] = {}
            //         }
            //         if (!events[element.event_name][date]) {
            //             events[element.event_name][date] = 1
            //         } else {
            //             ++events[element.event_name][date]
            //         }
            //     });
            //     Object.entries(events).forEach(([name, stats]) => {
            //         let data = []
            //         labels.forEach((month) => {
            //             let count = stats?.[month] || 0
            //             data.push(count)
            //         })

            //         datasets.push({
            //             name: name,
            //             data: data
            //         })
            //     })
            // } else if (this.statPeriod == 'year') {
            //     for (let month = 0; month < 12; month++) {
            //         const monthDate = this.$moment().month(month);
            //         const monthName = monthDate.format('MM.YYYY');
            //         labels.push(monthName);
            //     }
            //     let events = {}
            //     dataStat.forEach(element => {
            //         let date = this.$moment(element.event_datetime, 'YYYY-MM-DD HH:mm:ss').format('MM.YYYY')

            //         if (!events[element.event_name]) {
            //             events[element.event_name] = {}
            //         }
            //         if (!events[element.event_name][date]) {
            //             events[element.event_name][date] = 1
            //         } else {
            //             ++events[element.event_name][date]
            //         }
            //     });
            //     Object.entries(events).forEach(([name, stats]) => {
            //         let data = []
            //         labels.forEach((month) => {
            //             let count = stats?.[month] || 0
            //             data.push(count)
            //         })

            //         datasets.push({
            //             name: name,
            //             data: data
            //         })
            //     })
            // }

            labels = dataStat.map((el, ind) => interval[ind][0]);

            datasets.push({
                name: 'Вовлеченность',
                data: dataStat
            })

            this.$set(this.chartOptions.xaxis, 'categories', labels)
            this.$set(this, 'chartSeries', datasets)
            this.$set(this, 'statEventLoading', false)
        },

    }
}
</script>
<style lang="scss">
.monitoring-page {}
</style>
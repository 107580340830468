<template>
    <div class="login-form">
        <v-form @submit.prevent="onSubmit" class="form-login">
            <img src="../../assets/Logo.svg">
            <v-text-field v-model="login" autocomplete="off" outlined class="input"
                placeholder="Номер телефона" :error-messages="errors.login" :error="!!errors.login"></v-text-field>            

            <v-text-field v-model="password" :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" outlined placeholder="Пароль"
                color="secondary" class="input password-input" :error-messages="errors.password" :error="!!errors.password">
            </v-text-field>

            <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" block :disabled="buttonDisabled">
                Войти
            </v-btn>
        </v-form>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'LoginPage',
    // components: {
    //     vueTelInput
    // },
    mixins: [globalMethods],
    data() {
        return {
            defaultCountry: 'ru',
            login: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
            },
            password: '',
            remember: false,
            value: String,
            dialog: false
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
        if (this.$cookies.get('token')) {
            this.$router.push({ name: 'Monitoring' });
        }
    },
    watch: {

    },
    methods: {
        handlePhoneInput(value) {
            // Метод, вызываемый при вводе номера телефона
            this.login = value;
        },
        validatePhoneNumber() {
            // Метод для проверки номера телефона
            const isValid = this.$refs.phoneInput.validate(); // Вызов встроенной валидации
            if (!isValid) {
                this.$set(this, 'errors', {
                    login: 'Номер телефона неверный',
                })
            }
        },
        onSubmit() {
            this.$set(this, 'buttonDisabled', true)
            this.checkPhone({ phone: this.login })
                .then(data => {
                    let obj = {
                        login: "+7" + this.clearPhone(data.phone),
                        password: this.password
                    }
                    this.sendRequest('POST', obj, '/adminLogin', {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }, true)
                        .then(response => {
                            this.$set(this, 'buttonDisabled', false)
                            this.$cookies.set("token", response.token, {
                                path: "/",
                                maxAge: this.$moment().unix() + 60 * 60 * 12,
                            });
                            this.$cookies.set("name", response.name, {
                                path: "/",
                                maxAge: this.$moment().unix() + 60 * 60 * 12,
                            });
                            this.$router.push({ name: 'Monitoring' });
                        })
                        .catch(err => {
                            this.$set(this, 'errors', {
                                login: 'Неверный логин',
                                password: 'Неверный пароль',
                            })
                            this.$set(this, 'buttonDisabled', false)
                            // this.$store.commit('setError', err)
                        })
                })
                .catch(err => {
                    this.$set(this, 'buttonDisabled', false)
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
.login-form {
    width: 323px;

    .input {
        // height: 48px;
        /* задаем высоту в пикселях */
    }

    img {
        width: 100%;
        margin-bottom: 141px;
    }

    .caption {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1A1A1A;
        display: block;
        margin-bottom: 20px;
        text-align: center;
    }

    .remember-me {
        margin-top: 30px;
        text-align: center;

        span {
            color: #629F33;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .forgot {
        .heading {
            font-family: 'Circe';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;

            color: #000000;
        }

        .message {
            padding-top: 15px;
            font-family: 'Circe';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #1A1A1A;
        }
    }

}
</style>

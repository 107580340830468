const state = {
    isSubmitting: false,
    isLoggedIn: null,
    isLoading: false,
    currentUser: null,
    validationErrors: null,
    user: null,
    yandexOauthToken: 'y0_AgAAAAAMyGnmAAWW4AAAAADl1cyllyaLDutwQlG5nuPYYGv4AdAmM0I'
}
export const mutationTypes = {
}
export const actionTypes = {}
const getters = {}
const mutations = {}
const actions = {}

export default {
    state,
    actions,
    mutations,
    getters
}
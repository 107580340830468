<template>
    <div class="my-select" ref="select">
        <div class="label" :class="!getSelectValue ? 'placeholder' : ''" @click.self="optionsShow = !optionsShow">
        <span>
          <template v-if="getSelectValue">
            {{ getSelectValue }}
          </template>
          <template v-else>
            {{ placeholder }}
          </template>
        </span>
            <Icon width="20" height="20" viewBox="0 0 20 20" stroke="#809CB1" strockeWidth="2"
                  :iconName="'selector'"/>
        </div>
        <div class="list">
            <vue-slide-up-down class="options" :active="optionsShow == true">
                <ul>
                    <li v-for="i of items" @click="selected(i.value)" :key="`option-${i.value}`">
                        {{ i.label }}
                    </li>
                </ul>
            </vue-slide-up-down>
        </div>
    </div>
</template>
<script>
import {globalMethods} from '@/mixins/globalMethods'

export default {
    name: "autocomplete",
    props: {
        placeholder: {
            typeof: String,
            default: ''
        },
        value: {
            typeof: String,
            default: ''
        },
        items: {
            typeof: Array,
            default: () => {
                return []
            }
        },
        // model: {
        //     prop: 'value',
        //     event: 'input'
        // }
    },
    data() {
        return {
            //   item: {},
            optionsShow: false,
        };
    },
    mixins: [globalMethods],
    mounted() {
    },
    computed: {
        getSelectValue() {
            const v = this.items.find(i => i.value == this.value)
            if (v) {
                return v.label
            } else {
                return false
            }
        }
    },
    watch: {
        optionsShow: function (val) {
            const clickOutside = (e) => {
                if (this.handleClickOutside(e, 'select')) {
                    this.$set(this, 'optionsShow', false)
                }
            }
            if (val) {
                document.addEventListener('click', clickOutside);
            } else {
                document.removeEventListener('click', clickOutside);
            }
            return () => {
                document.removeEventListener('click', clickOutside);
            };
        }
    },
    methods: {
        hide() {
            this.$set(this, "optionsShow", false);
        },
        selected: function (item) {
            this.$set(this, "value", item);
            this.$set(this, "optionsShow", false);
            this.$emit('input', item)
            // this.$emit("selected", item);
        },
    },
};
</script>
<style lang="scss">
.my-select {
  position: relative;

  svg {
    stroke: var(--color-black);
  }

  .label {
    background: #FFFFFF;
    border: 1px solid #809CB1;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6F727A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    span {
      flex: 1;
      overflow: hidden;
      color: #000;
    }

    &.placeholder {
      span {
        opacity: 0.4;
      }
    }
  }

  .list {
    width: 100%;
    position: absolute;
    left: 0%;
    top: 75%;
    z-index: 9;
    padding: 0;
    border-radius: 12px;

    .options {
      > ul {
        transition: all 0.3s;
        position: relative;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 5px 0;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(16, 19, 26, 0.15);
        border-radius: 12px;
        list-style: none;
        max-height: 400px;
        overflow-y: auto;
        // scrollbar-width: none;
        // &::-webkit-scrollbar {
        // display: none;
        // }

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #6F727A;
          transition: all 0.3s;
          cursor: pointer;
          text-align: left;
          padding: 2px 16px;

          &:hover {
            background: rgba(234, 236, 239, 0.5);
          }

          &.group {
            &:hover {
              background: #fff;
            }

            .caption {
              padding: 10px 5px;
              font-weight: bold;
              font-style: italic;
            }

            ul {
              box-shadow: unset;
              padding: 0;

              li {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

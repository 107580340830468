<template>
    <div id="ecxursion-page">
        <Grid column="1" :gap="24">
            <Grid column="2" gap="32"
                :style="{ gridColumn: '1/3', gridTemplateColumns: '122px 1fr', alignItems: 'center' }">
                <v-btn class="button go-back" variant="tonal" color="primary" block @click="$router.back()">
                    <Icon width="20" height="20" viewBox="0 0 22 22" strockeWidth="2" iconName="arrow-left" />
                    Назад
                </v-btn>
                <div class="caption-block">
                    <h4>{{ captionText }}</h4>
                    <div class="breadcrumbs">
                        <router-link to="/excursions">Экскурсии</router-link>
                        <Icon width="20" height="20" viewBox="0 0 18 18" strockeWidth="0" :iconName="'chevron-right'"
                            fill="#333333" />

                        <span>{{ captionText }}</span>
                    </div>
                </div>
            </Grid>
            <Block>
                <Grid column="2" gap="16">
                    <v-form @submit.prevent="false" ref="form">
                        <Grid column="2" :style="{ rowGap: '24px', columnGap: '16px' }">
                            <div class="item-block column-2">
                                <div class="label">Название экскурсии</div>
                                <v-text-field placeholder="Введите" v-model="formData.title.RU" autocomplete="off"
                                    outlined class="input" required :error-messages="errors.title"
                                    :error="!!errors.title"></v-text-field>
                            </div>
                            <div class="item-block column-2">
                                <div class="label">Описание экскурсии</div>
                                <v-textarea placeholder="Введите описание" v-model="formData.descr.RU"
                                    autocomplete="off" outlined class="input" required :error-messages="errors.descr"
                                    :error="!!errors.descr"></v-textarea>
                            </div>
                            <div class="item-block">
                                <div class="label">Вид экскурсии</div>
                                <!-- <div class="grid-select-add"> -->
                                <v-app>
                                    <v-combobox v-model="tourTypeSelect" multiple :items="tourTypeOptions" solo
                                        item-text="label" item-value="value" label="Выберите элемент"></v-combobox>
                                </v-app>
                            </div>
                            <div class="item-block">
                                <div class="label">Способ передвижения</div>
                                <SelectBlock v-model="formData.transport_type" :items="transportTypesOptions"
                                    placeholder="Выберите"></SelectBlock>
                            </div>
                            <div class="item-block">
                                <v-checkbox v-model="formData.individual" label="Индивидуальная"></v-checkbox>
                            </div>
                            <div class="item-block">
                                <v-checkbox v-model="formData.visible" label="Активная"></v-checkbox>
                            </div>
                            <div class="item-block">
                                <div class="label">Длительность</div>
                                <v-text-field v-model.number="formData.duration" outlined class="input" type="number"
                                    step="0.01" :max="24"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Количество</div>
                                <v-text-field v-model="formData.max_people" outlined class="input"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Язык</div>
                                <SelectBlock v-model="formData.language_id" :items="languagesListOptions"
                                    placeholder="Выберите"></SelectBlock>
                            </div>
                            <div class="item-block">
                                <div class="label">Место проведения экскурсии</div>
                                <SelectBlock v-model="formData.city_id" :items="citiesOptions" placeholder="Выберите">
                                </SelectBlock>
                            </div>
                            <div class="item-block">
                                <div class="label">Питание</div>
                                <SelectBlock v-model="formData.meal_type_id" :items="meatOptions"
                                    placeholder="Выберите">
                                </SelectBlock>
                            </div>
                            <div class="item-block">
                                <div class="label">Город</div>
                                <v-app>
                                    <v-combobox v-model="citySelect" multiple :items="citiesOptions" solo
                                        item-text="label" item-value="value" label="Выберите элемент"></v-combobox>
                                </v-app>


                                <!-- <div class="grid-select-add">
                                    <SelectBlock v-model="tourTypeSelect" :items="citiesOptions" placeholder="Выберите">
                                    </SelectBlock>
                                    <button class="ghost border" @click.prevent="addTourType('city_ids')">
                                        <Icon width="20" height="20" viewBox="0 0 15 15" strockeWidth="1.5"
                                            :iconName="'plus'" />
                                    </button>
                                </div> -->
                            </div>
                            <div class="item-block column-2">
                                <v-checkbox v-model="isChecked" label="Питание включено"></v-checkbox>
                            </div>
                            <div class="item-block">
                                <div class="label">Новая Стоимость (Для взрослых)</div>
                                <v-text-field placeholder="Введите" v-model="formData.adult_price" autocomplete="off"
                                    outlined class="input" required :error-messages="errors.child_price"
                                    :error="!!errors.child_price"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Старая Стоимость (Для взрослых)</div>
                                <v-text-field placeholder="Введите" v-model="formData.old_adult_price"
                                    autocomplete="off" outlined class="input" required
                                    :error-messages="errors.child_price" :error="!!errors.child_price"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Новая Стоимость (Для детей 7-11 лет)</div>
                                <v-text-field placeholder="Введите" v-model="formData.child_price" autocomplete="off"
                                    outlined class="input" required :error-messages="errors.adult_price"
                                    :error="!!errors.adult_price"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Старая Стоимость (Для детей 7-11 лет)</div>
                                <v-text-field placeholder="Введите" v-model="formData.old_child_price"
                                    autocomplete="off" outlined class="input" required
                                    :error-messages="errors.adult_price" :error="!!errors.adult_price"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Новая Стоимость (Для детей 3-6 лет)</div>
                                <v-text-field v-model="formData.baby_price" placeholder="Введите" autocomplete="off"
                                    outlined class="input"></v-text-field>
                            </div>
                            <div class="item-block">
                                <div class="label">Старая Стоимость (Для детей 3-6 лет)</div>
                                <v-text-field v-model="formData.old_baby_price" placeholder="Введите" autocomplete="off"
                                    outlined class="input"></v-text-field>
                            </div>
                            <!-- <div class="item-block">
                                <div class="label">Скидка (%)</div>
                                <v-text-field placeholder="Введите" autocomplete="off" outlined
                                    class="input"></v-text-field>
                            </div> -->
                            <div class="item-block column-2">
                                <div class="label">Примечание</div>
                                <v-textarea placeholder="Введите описание" v-model="formData.notes" autocomplete="off"
                                    outlined class="input" required :error-messages="errors.notes"
                                    :error="!!errors.notes"></v-textarea>
                            </div>
                            <div class="item-block column-2">
                                <div class="label">Частые вопросы</div>
                                <div class="faq">
                                    <div class="inline">
                                        <v-text-field placeholder="Введите вопрос" v-model="query" autocomplete="off"
                                            outlined class="input"></v-text-field>
                                        <button class="ghost border" @click.prevent="addFaq()">
                                            <Icon width="20" height="20" viewBox="0 0 15 15" strockeWidth="1.5"
                                                :iconName="'plus'" />
                                        </button>
                                    </div>
                                    <v-textarea placeholder="Введите ответ" v-model="answer" autocomplete="off" outlined
                                        class="input"></v-textarea>
                                </div>
                                <ol class="faq-list">
                                    <li v-for="(f, ind) in formData.faq" :key="ind + $moment.unix()">
                                        <div @click="deleteFaq(ind)">
                                            <b>{{ f.question }}</b>
                                        </div>
                                        {{ f.answer }}
                                    </li>
                                </ol>
                            </div>
                        </Grid>
                    </v-form>
                    <div class="images-block">
                        <div class="label">Фото</div>
                        <input v-show="false" type="file" ref="img" multiple @change="uploadImg()" />
                        <div class="dropzone" @dragenter.prevent="onDragEnter" @dragleave.prevent="onDragLeave"
                            @dragover.prevent="onDragOver" @drop.prevent="onDrop">

                            <Icon width="32" height="32" viewBox="0 0 32 32" strockeWidth="2" :iconName="'photograph'"
                                stroke="#999999" />
                            <p v-if="!isDragging"><span @click="$refs['img'].click()">Загрузить файл</span> или
                                перетащите</p>
                            <p v-else>Отпустите файлы для загрузки</p>
                            <p>PNG, JPG размером до 5 МБ</p>
                        </div>
                        <div class="img-list">
                            <div class="img-list__img" v-for="(img, ind) of formData.images" :key="`img-${img}`">
                                <button class="delete-circle" @click="deleteImg(ind)">
                                    <Icon width="16" height="16" viewBox="0 0 20 20" strockeWidth="1.5"
                                        :iconName="'trash'" stroke="#D10A0F" />
                                </button>
                                <img :src="mediaServerUrl + img" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="form-button">
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="onSubmit()">
                            Сохранить
                        </v-btn>
                    </div>
                </Grid>
            </Block>
        </Grid>
        <Modal ref="notification" :notFooterBorder="true" padding="40" :hiddeCloseBtn="true" :hiddeFooter="false">
            <template v-slot:header>
                <template v-if="isNew">
                    Экскурсия добавлена
                </template>
                <template v-else>
                    Экскурсия изменена
                </template>
            </template>
            <template v-slot:footer>
                <v-btn type="submit" class="button mt-6" variant="tonal" color="primary"
                    @click="$refs['notification']._confirm()">
                    Продолжить
                </v-btn>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { globalMethods } from '@/mixins/globalMethods'
import Block from "@/components/Block.vue";


export default {
    name: 'ExcursionPage',
    components: { Block },
    mixins: [globalMethods],
    computed: {
        ...mapState({
            mediaServerUrl: state => state.mediaServerUrl,
            language: state => state.language,
        }),
        isNew: function () {
            return this.id === null
        },
        captionText: function () {
            if (this.isNew) {
                return 'Добавление экскурсии'
            } else {
                return 'Изменить экскурсию'
            }
        },
        tourTypeOptions: function () {
            if (this.tourType) {
                return Object.entries(this.tourType).reduce((result, [value, label]) => {
                    return [...result, { label, value }]
                }, [])
            } else {
                return []
            }
        },
        transportTypesOptions: function () {
            if (this.transportTypes) {
                return Object.entries(this.transportTypes).reduce((result, [value, label]) => {
                    return [...result, { label, value }]
                }, [])
            } else {
                return []
            }
        },
        citiesOptions: function () {
            if (this.cities) {
                return Object.entries(this.cities).reduce((result, [value, label]) => {
                    return [...result, { label, value }]
                }, [])
            } else {
                return []
            }
        },
        languagesListOptions: function () {
            if (this.cities) {
                return Object.entries(this.languagesList).reduce((result, [value, label]) => {
                    return [...result, { label, value }]
                }, [])
            } else {
                return []
            }
        },

    },
    data() {
        return {
            meatOptions: [],
            items: [
                'Вариант 1',
                'Вариант 2',
                'Вариант 3',
                'Вариант 4',
            ],
            selectedItem: null,
            tourTypeSelectData: [],
            tourTypeOptionsList: [],
            tourTypeSelect: null,
            citySelect: null,
            tourTypeCh: false,
            isChecked: false,
            answer: '',
            query: '',
            errors: {
                title: '',
                descr: '',
                child_price: '',
                adult_price: '',
                notes: '',
            },
            tourType: {},
            transportTypes: {},
            cities: {},
            languagesList: {},
            id: null,
            isDragging: false,
            formData: {
                city_id: "",
                tour_type: "",
                transport_type: "",
                title: { "RU": "", "EN": "" },
                descr: { "RU": "", "EN": "" },
                max_people: 0,
                duration: 0,
                language_id: "",
                meal_type_id: "",
                main_image: "",
                images: [],
                adult_price: 0,
                child_price: 0,
                old_adult_price: 0,
                old_child_price: 0,
                faq: [],
                notes: "",
                individual: false

            },
            schedule: []
        }
    },
    mounted() {
        if (this.$route.params.excId) {
            if (this.$route.params.excId == 'new') {

            } else {
                this.$set(this, 'id', this.$route.params.excId)
                this.sendRequest('GET', { page: 1, size: 1, id: this.$route.params.excId }, '/getTours', {}, true)
                    .then(response => {

                        let data = response
                        try {
                            data.title = JSON.parse(data.title)
                            data.descr = JSON.parse(data.descr)
                        } catch (err) {
                            console.error(err)
                        }
                        this.$set(this, 'formData', data)

                    })
                    .catch(err => {
                        this.$router.push('/excursions')
                        this.$store.commit('setError', err)
                    })

                // this.sendRequest('GET', { id: this.$route.params.excId }, '/getSchedule', {}, true)
                //     .then(response => {
                //         this.$set(this, 'schedule', response)
                //     })
                //     .catch(err => {
                //         this.$router.push('/excursions')
                //         this.$store.commit('setError', err)
                //     })
            }
            this.sendRequest('GET', {}, '/getMealTypes', {}, true)
                .then(response => {
                    this.meatOptions = response.reduce((result, items) => {
                        let label = JSON.parse(items.name)[this.language],
                            value = items.id
                        return [...result, { label, value }]
                    }, [])

                })
        } else {
            return this.$router.push('/excursions')
        }
        Promise.all([
            this.loadGuide('tourType', '/getTourTypes'),
            this.loadGuide('transportTypes', '/getTransportTypes'),
            this.loadGuide('cities', '/getCities'),
            this.loadGuide('languagesList', '/getLanguages'),
        ]).then(([tourType, transportTypes, cities, languagesList]) => {
            this.$set(this, 'tourType', tourType)
            this.$set(this, 'transportTypes', transportTypes)
            this.$set(this, 'cities', cities)
            this.$set(this, 'languagesList', languagesList)
        })
        // this.sendRequest('GET', {}, '/getTransportTypes', {}, true)
        //     .then(response => {
        //         this.$set(this, 'goodsList', response)
        //     })
        //     .catch(err => {
        //         this.$store.commit('setError', err)
        //     })
    },
    watch: {
        'formData.duration': function (val) {
            if (val > 72) {
                val = 72
            }
            let self = this
            setTimeout(() => {
                this.$set(this.formData, 'duration', val)
                this.formData.duration = val
            }, 1)
        },
        'tourTypeOptions': function (val) {
            if (val.length > 0) {
                this.tourTypeSelect = val.filter(t => this.formData.tour_types.includes(t.value))
            }
        },
        'citiesOptions': function (val) {
            if (val.length > 0) {
                this.citySelect = val.filter(t => this.formData.city_ids.includes(t.value))
            }
        },
    },
    methods: {
        remove(item, field) {
            let itemIndex = this[field].findIndex(el => el.value == item.value)
            this.$delete(this[field], itemIndex)
        },
        addTourType(field) {
            if (!this.formData[field]) {
                this.formData[field] = []
            }
            this.formData[field] = [...this.formData[field], this.tourTypeSelect]
            this.tourTypeSelect = null
        },
        deleteTourType(ind, field) {
            this.$delete(this.formData[field], ind)
        },
        deleteFaq(ind) {
            this.$delete(this.formData.faq, ind)
        },
        addFaq() {
            let faq = this.formData?.faq || []
            faq.push(
                { question: this.query, answer: this.answer }
            )
            this.$set(this.formData, 'faq', faq)
            this.$set(this, 'query', '')
            this.$set(this, 'answer', '')
        },
        deleteImg(ind) {
            this.$delete(this.formData.images, ind)
        },
        onSubmit() {
            this.formData.individual = this.formData.individual ? 1 : 0
            this.formData.visible = this.formData.visible ? 1 : 0
            this.formData.main_image = this.formData?.images?.[0] || ''
            this.formData.tour_types = this.tourTypeSelect.reduce((result, item) => {
                return [
                    ...result,
                    item.value
                ]
            }, [])
            this.formData.city_ids = this.citySelect.reduce((result, item) => {
                return [
                    ...result,
                    item.value
                ]
            }, [])


            this.sendRequest('POST', this.formData, '/addTour', {}, true)
                .then(response => {
                    this.$refs['notification'].show().then(r => {
                        if (this.isNew) {
                            location.href = `/excursions/${response.id}`
                        }
                    })
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        uploadImg() {
            let files = this.$refs.img.files;
            for (var i = 0; i < files.length; i++) {
                let form = new FormData(),
                    file = files[i];
                form.append("content", file);
                this.sendRequest('POST', form, '/uploadFile', { "content-type": "multipart/form-data", }, false)
                    .then(response => {

                        if (response) {
                            let imgs = [...this.formData.images, response.name]
                            this.$set(this.formData, 'images', imgs)
                        }
                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
        onDragEnter() {
            this.isDragging = true;
        },
        onDragLeave() {
            this.isDragging = false;
        },
        onDragOver(event) {
            event.dataTransfer.dropEffect = 'copy';
        },
        onDrop(event) {
            event.preventDefault();
            this.isDragging = false;

            const files = Array.from(event.dataTransfer.files);

            for (var i = 0; i < files.length; i++) {
                let form = new FormData(),
                    file = files[i];
                form.append("content", file);
                this.sendRequest('POST', form, '/uploadFile', { "content-type": "multipart/form-data", }, false)
                    .then(response => {

                        if (response) {
                            let imgs = [...this.formData.images, response.name]
                            this.$set(this.formData, 'images', imgs)
                        }
                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }


            // Далее можно обработать загруженные файлы
        },

    }
}
</script>
<style lang="scss">
#ecxursion-page {
    .label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #6F727A;
        margin-bottom: 8px;
    }

    .images-block {
        .dropzone {
            height: 264px;
            border: 1px dashed #809CB1;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 32px;

            svg {
                margin-bottom: 10px;
            }

            p {
                &:nth-child(2) {
                    margin-bottom: 5px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;

                    span {
                        color: #003A63;
                        cursor: pointer;
                    }
                }

                &:last-child {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #6F727A;
                }
            }
        }
    }

    .column-2 {
        grid-column: 1/3;
    }

    .faq {
        .inline {
            display: grid;
            grid-template-columns: 1fr 52px;
            gap: 8px;
            margin-bottom: 12px;

            .my-select {}

            .ghost.border {
                /* Auto layout */
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 16px;
                gap: 10px;
                width: 52px;
                height: 48px;
                background: #FFFFFF;
                border: 1px solid #809CB1 !important;
                border-radius: 8px;
            }
        }

    }
}

.v-form {
    padding: 20px 0 20px 20px;
}

.images-block {
    padding: 20px 20px 20px 0;
}

.form-button {
    grid-column: 1/3;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #809CB1;
}

.img-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    &__img {
        position: relative;

        .delete-circle {
            position: absolute;
            width: 32px;
            height: 32px;
            top: -16px;
            right: -16px;
            background: #fff;
            border: 1px solid #809CB1;
            border-radius: 50%;
            z-index: 2;
            display: flex;
            align-items: center;

            justify-content: center
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.faq-list {
    padding-left: 20px;
    margin-top: 20px;

    li {
        div {
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: red;
            }
        }
    }
}

.grid-select-add {
    display: grid;
    grid-template-columns: 1fr 52px;
    gap: 5px;

    button {
        &.ghost.border {
            /* Auto layout */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px 16px;
            gap: 10px;
            width: 52px;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid #809CB1 !important;
            border-radius: 8px;
        }
    }
}

.item-block {
    ul {
        padding-left: 17px;

        li {
            cursor: pointer;
            transition: color .3s;

            &:hover {
                color: red;
            }
        }
    }
}
</style>

<template>
    <div class="login-form">
        <router-link to="/policy/privacy_policy">Privacy policy</router-link> and 
        <router-link to="/policy/terms_of_use">Terms of use</router-link>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'LoginPage',
    // components: {
    //     vueTelInput
    // },
    mixins: [globalMethods],
    data() {
        return {
            defaultCountry: 'ru',
            login: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
            },
            password: '',
            remember: false,
            value: String,
            dialog: false
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {        
    },
    watch: {

    },
    methods: {       
    }
}
</script>
<style lang="scss">
.login-form {
    width: 323px;

    .input {
        // height: 48px;
        /* задаем высоту в пикселях */
    }

    img {
        width: 100%;
        margin-bottom: 141px;
    }

    .caption {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1A1A1A;
        display: block;
        margin-bottom: 20px;
        text-align: center;
    }

    .remember-me {
        margin-top: 30px;
        text-align: center;

        span {
            color: #629F33;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .forgot {
        .heading {
            font-family: 'Circe';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;

            color: #000000;
        }

        .message {
            padding-top: 15px;
            font-family: 'Circe';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #1A1A1A;
        }
    }

}
</style>

<template>
  <div id="filters-page">
    <Grid column="1" :style="gridStyle">
      <div class="caption-block">
        <h4>Фильтры</h4>
        <span>Фильтры</span>
      </div>
      <Block :padding="0" :custom-style="{ overflow: 'hidden' }">
        <Grid column="1" :style="{ background: '#809CB1', rowGap: '1px' }">
          <div class="grid-block tabs">
            <ul>
              <li v-for="tab of tabs" :key="tab" :class="{ active: tab.id == activeTab }" @click="activeTab = tab.id">{{
                tab.name }}
              </li>
            </ul>
          </div>
          <div class="grid-block values">
            <ul>
              <li v-for="(name, id) of tabValues?.[activeTab] || []" :key="id" @click="deleteFilter(id)">
                {{ name }}
                <Icon width="16" class="close-icon" height="16" viewBox="0 0 15 15" strockeWidth="1" :iconName="'x'"
                  stroke="#000" />
              </li>
            </ul>
          </div>
          <div class="grid-block last">
            <v-btn class="button mt-6" variant="tonal" color="primary" @click="showModal('add-filter')">
              Добавить фильтр
            </v-btn>
          </div>
        </Grid>
      </Block>
    </Grid>
    <Modal ref="add-filter" :notFooterBorder="true" padding="40">
      <template v-slot:header>
        {{ captionAddModal }}
      </template>
      <template v-slot:subheader>
        Добавьте новое значение в фильтр
      </template>
      <template v-slot:body>
        <v-form @submit.prevent="false" class="new-admin-form">

          <v-text-field v-model="nameNewFilter" autocomplete="off" outlined class="input"
            placeholder="Введите новый фильтр"></v-text-field>
        </v-form>
      </template>
      <template v-slot:footer>
        <div class="button-block">
          <button class="ghost" @click="$refs['add-admin']._cancel()">
            Отменить
          </button>
          <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="saveFilter()">
            Сохранить
          </v-btn>
        </div>
      </template>
    </Modal>
    <Modal ref="delete-filter" :notFooterBorder="true" padding="40">
      <template v-slot:header>
        <div class="del-icon">
          <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'trash'" stroke="#D10A0F" />
        </div>
        Удалить фильтр
      </template>
      <template v-slot:body>
        Вы действительно хотите удалить фильтр
        <div class="color-red">Это действие является постоянным и не может быть отменено!</div>
      </template>
      <template v-slot:footer>
        <div class="button-block">
          <button class="ghost black" @click="$refs['delete-filter']._cancel()">
            Отменить
          </button>
          <v-btn type="submit" class="button mt-6 error" variant="tonal" color="primary" @click="deleteFilterConfirm()">
            Удалить
          </v-btn>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import Block from "@/components/Block.vue";
import Icon from "@/components/Icons.vue";
import { actionTypes } from "@/store/modules/auth";

export default {
  name: 'AdministrationsPage',
  components: { Icon, Block },
  mixins: [globalMethods],
  data() {
    return {
      nameNewFilter: '',
      gridStyle: { rowGap: '24px' },//, gridTemplateRows: '78px 1fr', height: '100vh'
      deleteId: null,
      activeTab: 'tourType',
      tabs: [
        {
          id: 'tourType',
          name: 'Разновидность экскурсии'
        },
        {
          id: 'transportTypes',
          name: 'Способ передвижения'
        },
      ],
      tabValues: {},
      sp: {
        'tourType': 'TourType',
        'transportTypes': 'TransportType',
      }
    }
  },
  computed: {
    ...mapState({
      //   isSubmitting: state => state.auth.isSubmitting,
      //   validationErrors: state => state.auth.validationErrors
    }),
    captionAddModal: function () {
      return this.tabs.find(t => t.id == this.activeTab).name
    }
  },
  mounted() {
    Promise.all([
      this.loadGuide('tourType', '/getTourTypes'),
      this.loadGuide('transportTypes', '/getTransportTypes'),
    ]).then(([tourType, transportTypes]) => {
      this.$set(this, 'tabValues', { tourType, transportTypes })
    })
    // this.sendRequest('GET', {}, '/getAdmins', {}, true)
    //     .then(response => {
    //         this.$set(this, 'goodsList', response)
    //     })
    //     .catch(err => {
    //         this.$store.commit('setError', err)
    //     })
  },
  watch: {
    filter: {
      deep: true,
      handler(newVal, oldVal) {

      },
    }
  },
  methods: {
    reload() {
      Promise.all([
        this.loadGuide('tourType', '/getTourTypes'),
        this.loadGuide('transportTypes', '/getTransportTypes'),
      ]).then(([tourType, transportTypes]) => {
        this.$set(this, 'tabValues', { tourType, transportTypes })
      })
    },
    saveFilter() {
      this.sendRequest('POST',
        {
          name: { "RU": this.nameNewFilter, "EN": this.nameNewFilter }
        },
        `/add${this.sp[this.activeTab]}`, {}, true
      )
        .then(response => {
          this.$store.commit(`set${this.activeTab}`, undefined)
          this.$refs['add-filter']._confirm()
          this.reload()
        })
        .catch(err => {
          this.$store.commit('setError', err)
        })
    },
    deleteFilter(id) {
      this.$set(this, 'deleteId', id)
      this.showModal('delete-filter')
    },
    deleteFilterConfirm() {
      this.sendRequest('DELETE', { id: this.deleteId }, `/remove${this.sp[this.activeTab]}`, {}, true)
        .then(response => {

          this.$delete(this.tabValues?.[this.activeTab], this.deleteId)

          this.$set(this, 'deleteId', null)
          this.$refs['delete-filter']._confirm()          
        })
        .catch(err => {
          this.$store.commit('setError', err)
        })
    },
    deleteAdmin(id) {
      this.$set(this, 'deleteId', id);
      this.showModal('delete-admin')

    },
    editAdmin(admin) {
      this.$set(this, 'newAdmin', { ...admin, type: 'admin' })
      this.showModal('add-admin')
    },
    createAdmin() {
      if (this.newAdmin?.id) {

      } else {
        this.checkPhone(this.newAdmin)
          .then(this.checkName)
          .then(data => {
            this.sendRequest('POST', data, '/addAdmin', {}, true)
              .then(response => {
                let newAdmins = [...this.admins, response]
                this.$set(this, 'admins', newAdmins)
                this.$set(this, 'newAdmin', {
                  name: "",
                  phone: "",
                  user_type: "admin",
                  state: "active"
                })
                this.$refs['add-admin']._confirm()
              })
              .catch(err => {
                this.$store.commit('setError', err)
              })
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
      }
      this.$refs['add-admin']._confirm()
    },
    changePage(state) {
      if (state === 'prev') {
        if (this.filter.page > 1) {
          this.$set(this.filter, 'page', --this.filter.page)
          this.$set(this, 'showListNow', --this.filter.page)
        }
      } else if (state === 'next') {
        if (this.filter.page < this.countList) {
          this.$set(this.filter, 'page', ++this.filter.page)
          this.$set(this, 'showListNow', ++this.filter.page)
        }
      }
    },
  }
}
</script>
<style lang="scss">
#filters-page {
  .v-form {
    padding: 0;
  }

  .grid-block {
    background: #fff;
    padding: 20px;

    &.tabs {
      background: #F7F7F9;
    }

    &.tabs,
    &.values {

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 10px 16px;
          background: #FFFFFF;
          border-radius: 8px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #6F727A;
          cursor: pointer;

          &.active {
            background: #DCECFB;
            color: #003A63;
          }

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    &.values {
      ul {
        flex-wrap: wrap;
        gap: 20px;

        li {
          border-radius: 20px;
          border: 1px solid #809CB1;
          transition: all .3s;
          color: #333333;
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          white-space: nowrap;

          svg {
            margin-left: 15px;
          }

          &:hover {
            background: #fbdcdc;
            color: #630000;
            border-color: #b18080;
          }

          &:not(:last-child) {
            margin-right: 0px;
          }
        }
      }
    }

    &.last {
      display: flex;
      justify-content: flex-end;
    }
  }

  .edit-btn {
    margin-right: 15px !important;
    padding-right: 15px !important;
    position: relative;

    &:after {
      content: "";
      width: 1px;
      height: 20px;
      background: #D9D9D9;
      position: absolute;
      right: 0px;
      display: block;
    }
  }

  .new-admin-form {
    label {
      display: block;
      margin-bottom: 40px;

      >span {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #6F727A;
        margin-bottom: 8px;
      }
    }

    .row-select {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      label {
        margin-bottom: 0px;
      }
    }
  }

  .button-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ghost {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-align: center !important;
      color: #003A63 !important;
      margin-right: 24px !important;

      &.black {
        color: #000 !important;
      }
    }
  }

  .color-red {
    color: #D10A0F;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
  }

  .del-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #FFDBDB;
    border-radius: 40px;
    margin-right: 16px;
  }

  .notification-success {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #6F727A;
    padding-bottom: 40px;

    .icon {
      background: #249F5D;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .caption {
      margin-top: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      margin-bottom: 16px;
    }
  }
}
</style>

import Vue from 'vue'

/** form */
import Heading from "@/components/Heading.vue";
import Block from "@/components/Block.vue";
import Grid from "@/components/Grid.vue";
import TableBlock from "@/components/TableBlock.vue";
import Icon from "@/components/Icons.vue";
import Modal from '@/components/ModalDialog'
import SelectBlock from '@/components/MySelect'
import SelectAutocomplete from '@/components/SelectAutocomplete'
const components = { Icon, Modal, Heading, Block, Grid, TableBlock, SelectBlock, SelectAutocomplete }

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component)
})
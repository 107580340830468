import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login"
import Monitoring from "../views/Monitoring"
import Administrations from "../views/Administrations"
import Excursions from "../views/Excursions"
import Excursion from "../views/Excursions/_id.vue"
import ExcursionCard from "../views/Excursions/_card.vue"
import Filters from "../views/Filters"
import Orders from "../views/Orders"
import Order from "../views/Orders/_order.vue"

import Policy from "../views/Policy"
import PolicyPrivate from "../views/Policy/privacyPolicy.vue"
import PolicyTermsOfUse from "../views/Policy/termsOfUse.vue"

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { layout: "login-layout" }
    },
    {
        path: '/monitoring',
        name: 'Monitoring',
        component: Monitoring,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/administrations',
        name: 'Administrations',
        component: Administrations,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/excursions',
        name: 'Excursions',
        component: Excursions,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/excursions/:excId',
        name: 'Excursion',
        component: Excursion,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/excursions/card/:excId',
        name: 'ExcursionCard',
        component: ExcursionCard,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/filters',
        name: 'Filters',
        component: Filters,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/orders/:orderId',
        name: 'Order',
        component: Order,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
        meta: { layout: "login-layout" }
    },
    {
        path: '/policy/privacy_policy',
        name: 'PolicyPrivate',
        component: PolicyPrivate,
        meta: { layout: "login-layout" }
    },
    {
        path: '/policy/terms_of_use',
        name: 'PolicyTermsOfUse',
        component: PolicyTermsOfUse,
        meta: { layout: "login-layout" }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Vue.$cookies.get('token')) {
            next()
        } else {
            next({ path: '/' })
        }
    } else {
        next()
    }

})

export default router

<template>
  <div id="orders-page">
    <Grid column="1" :style="gridStyle">
      <div class="caption-block">
        <h4>Заказы</h4>
        <span>Заказы</span>
      </div>
      <Grid column="4" gap="24" :style="{ gridColumn: '1/3' }">
        <v-text-field v-model="filter.booking_code" autocomplete="off" outlined class="input autocomplete"
          placeholder="Поиск заказов">
          <template #append>
            <v-icon>mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </Grid>
      <Block :style="{ gridColumn: '1/3', padding: '0', overflow: 'unset' }">
        <TableBlock>
          <template v-slot:head>
            <tr>
              <th class="w-85">
                <span @click="sortData('booking_code')" class="sort">#</span>
                <v-text-field v-model="filter.booking_code" outlined class="input" placeholder="Все"></v-text-field>
              </th>
              <th class="date" ref="dp">
                <span @click="sortData('tour_date')" class="sort">Дата</span>
                <v-text-field :value="filter.tour_date.join(' — ')" outlined class="input" placeholder="Все" readonly
                  @click="dpShow = true">
                  <template #append>
                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'calendar'"
                      stroke="#809CB1" />
                  </template>
                </v-text-field>
                <transition name="fade">
                  <div class="datepicker-block" v-show="dpShow">
                    <v-date-picker v-model="filter.tour_date" :first-day-of-week="1" locale="ru-RU"
                      :weekday-format="getCustomDay" range></v-date-picker>
                  </div>
                </transition>
                <!--                                <v-text-field v-model="filter.date" outlined class="input"-->
                <!--                                              placeholder="Все"></v-text-field>-->
              </th>
              <th class="phone">
                <span @click="sortData('tour_name')" class="sort">Экскурсия</span>
                <v-text-field v-model="filter.tour_name" outlined class="input" placeholder="Все"></v-text-field>
              </th>
              <th class="phone">
                <span @click="sortData('hotel_name')" class="sort">Отель</span>
                <v-text-field v-model="filter.hotel_name" outlined class="input" placeholder="Все"></v-text-field>
              </th>
              <th class="phone">
                <span @click="sortData('address')">Местоположение</span>
                <v-text-field v-model="filter.address" outlined class="input" placeholder="Все"></v-text-field>
              </th>
              <th class="w-150">
                <span @click="sortData('price')" class="sort">Сумма</span>
                <div class="gr">

                  <v-text-field v-model="filter.amount.from" outlined class="input" placeholder="Все"></v-text-field>
                  <div>—</div>
                  <v-text-field v-model="filter.amount.to" outlined class="input" placeholder="Все"></v-text-field>

                </div>
              </th>
              <th class="w-150">
                <span>Партнер</span>
                <v-text-field  v-model="filter.partner_id" outlined class="input" placeholder="Все"></v-text-field>
              </th>
              <th class="w-150">
                <span>Промокод</span>
                <!-- 5DB46 -->
                <v-text-field v-model="filter.promo" outlined class="input" placeholder="Все"></v-text-field>
              </th>
              <th class="w-150">
                <span @click="sortData('state')" class="sort">Статус</span>
                <SelectBlock v-model="filter.state" :items="states"></SelectBlock>
              </th>
              <th></th>
            </tr>
          </template>
          <template v-slot:body>
            <template v-if="orders.length > 0">
              <tr v-for="(order, ind) of orders" :key="'admin-' + order?.id">
                <td class="w-85">{{ order.booking_code }}</td>
                <td class="name">{{ order.tour_date }}</td>
                <td class="name">{{ order.tour_name }}</td>
                <td class="phone">{{ order.hotel_name }}</td>
                <td class="phone">{{ order.address }}</td>
                <td class="w-150">{{ order.price }}</td>
                <td class="w-150">{{ order?.partner || '' }}</td>
                <td class="w-150">{{ order?.promo || '' }}</td>
                <td class="w-150">{{ status[order.state] }}</td>
                <td>
                  <div></div>
                  <button class="ghost edit-btn" @click="$router.push(`/orders/${order?.id}`)">
                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'chat'" />
                  </button>
                  <button class="ghost delete-btn" @click="deleteAdmin(order, ind)">
                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5" :iconName="'x-circle'" />
                  </button>
                </td>
              </tr>
            </template>
          </template>
        </TableBlock>
        <div class="table-control">
          <div class="count-items">
            <!-- <span>Строк на странице:</span>
            <SelectBlock v-model="filter.size" :items="countItems"></SelectBlock> -->
          </div>
          <div class="pagination">
            <ul>
              <li>
                <button class="ghost" @click="changePage('prev')">
                  <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0" :iconName="'chevron-left'"
                    fill="#6F727A" />
                </button>
              </li>
              <!-- <li :class="{ actvie: page == '' + showListNow }">1</li> -->
              <li :class="{ actvie: page == '' + showListNow }">{{ page }}</li>
              <!-- <li>...</li> -->
              <!-- <li :class="{ actvie: page == showListNow }">{{ countList }}</li> -->
              <li>
                <button class="ghost" @click="changePage('next')">
                  <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0" :iconName="'chevron-right'"
                    fill="#6F727A" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Block>
    </Grid>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { globalMethods } from '@/mixins/globalMethods'

export default {
  name: 'OrdersPage',
  mixins: [globalMethods],
  data() {
    return {
      sortColumn: '',
      sortType: '',
      dpShow: false,
      errors: {
        name: '',
        phone: '',
      },
      showListNow: 1,
      countList: 8,
      countItems: [
        {
          value: '10',
          label: '10',
        },
        {
          value: '30',
          label: '30',
        },
        {
          value: '50',
          label: '50',
        },
        {
          value: '100',
          label: '100',
        },
      ],
      types: [
        {
          value: '',
          label: 'Все',
        },
        {
          value: 'admin',
          label: 'Админ',
        }
      ],
      states: [
        {
          value: '',
          label: 'Все',
        },
        {
          value: 'new',
          label: 'Новый',
        },
        {
          value: 'paid',
          label: 'Oплаченный',
        },
        {
          value: 'cancelled',
          label: 'Отмененный',
        },
      ],
      userType: {
        'admin': 'Админ',
      },
      status: {
        'new': 'Новый',
        'paid': 'Oплаченный',
        'cancelled': 'Отмененный',
      },
      gridStyle: { rowGap: '24px' },//, gridTemplateRows: '78px 1fr', height: '100vh'
      orders: [],
      ordersList: [],
      ordersClear: [],
      page: '1',
      filter: {
        booking_code: '',
        name: '',
        address: '',
        tour_date: [],
        user_type: '',
        state: '',
        hotel_name: '',
        tour_name: '',
        amount: {
          from: null,
          to: null,
        }
        // size: '10',
      },
      newAdmin: {
        name: '',
        phone: '',
        user_type: 'admin',
        state: 'active',
      },
      deleteId: null,
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
      //   validationErrors: state => state.auth.validationErrors
    })
  },
  mounted() {
    this.filterOrders = debounce(this.filterOrders, 500)
    this.filterRequest()

    // this.sendRequest('GET', { page: 1}, '/getBookings', {}, true)
    //   .then(response => {
    //     this.$set(this, 'orders', response)
    //   })
    //   .catch(err => {
    //     this.$store.commit('setError', err)
    //   })
  },
  watch: {
    dpShow: function (val) {
      const clickOutside = (e) => {
        if (this.handleClickOutside(e, 'dp')) {
          this.$set(this, 'dpShow', false)
        }
      }
      if (val) {
        document.addEventListener('click', clickOutside);
      } else {
        document.removeEventListener('click', clickOutside);
      }
      return () => {
        document.removeEventListener('click', clickOutside);
      };
    },
    filter: {
      deep: true,
      handler(newVal, oldVal) {
        this.filterOrders()
      },
    }
  },
  methods: {
    sortData(column) {
      if (this.sortColumn != column) {
        this.sortColumn = column
        this.sortType = 'desc'
      } else {
        switch (this.sortType) {
          case 'desc':
            this.sortType = 'ask';
            break
          case 'ask':
            this.sortColumn = ''
            this.sortType = '';
            break
        }
      }
      this.filterOrders()
    },
    changePageData() {
      const pageIndex = this.page - 1
      const currentPageData = this.ordersList?.[pageIndex] || []
      this.$set(this, 'orders', currentPageData)
    },
    filterOrders() {
      const filterData = Object.entries(this.filter).filter(([key, value]) => !!value)

      if (!!filterData.length) {

        let orders = this.ordersClear
        filterData.forEach(f => {
          const key = f[0]
          let value = f[1]
          orders = orders.filter(o => {
            if (key === 'amount') {
              if (
                !isNaN(value.from)
                && !isNaN(value.to)
                && typeof value.from === "number"
                && typeof value.to === "number"
              ) {
                console.log(value.from <= o.price && o.price < value.to)
                return value.from <= o.price && o.price <= value.to
              } else {
                return o
              }

            } else if (key === 'tour_date' && value.length > 0) {
              const momentDateToCheck = this.$moment(o.tour_date, 'DD.MM.YYYY');
              const momentStartDate = this.$moment(value[0], 'YYYY-MM-DD');
              const momentEndDate = this.$moment(value[1] || value[0], 'YYYY-MM-DD');

              return momentDateToCheck.isBetween(momentStartDate, momentEndDate, null, "[]");
            } else if (key === 'hotel_name') {
              value = value.toLocaleLowerCase()
              let inSearch = ('' + o.hotel_name).toLowerCase()
              return inSearch.includes(value)
            } else if (key === 'tour_name') {
              value = value.toLocaleLowerCase()
              let inSearch = ('' + o.tour_name).toLowerCase()
              return inSearch.includes(value)
            } else if (key === 'address') {
              value = value.toLocaleLowerCase()
              let inSearch = (o.city_name + ', ' + o.address).toLowerCase()
              return inSearch.includes(value)
            } else {
              const oD = '' + o[key]
              return oD.indexOf(value) > -1
            }
          })
        })

        if (!!this.sortColumn) {
          let self = this
          orders.sort((a, b) => {
            let left = '',
              right = ''

            if (self.sortColumn == 'price') {
              left = a.hasOwnProperty(self.sortColumn) ? parseInt(a[self.sortColumn]) : 0
              right = b.hasOwnProperty(self.sortColumn) ? parseInt(b[self.sortColumn]) : 0


              if (self.sortType == 'desc') {
                return a[self.sortColumn] - b[self.sortColumn]
              } else {
                return b[self.sortColumn] - a[self.sortColumn]
              }
            } else {
              left = a.hasOwnProperty(self.sortColumn) ? '' + a[self.sortColumn] : ''
              right = b.hasOwnProperty(self.sortColumn) ? '' + b[self.sortColumn] : ''


              if (self.sortType == 'desc') {
                // return a[self.sortColumn] - b[self.sortColumn]
                return left.localeCompare(right)
              } else {
                // return b[self.sortColumn] - a[self.sortColumn]
                return right.localeCompare(left)
              }
            }
          })
        }

        let ordersList = this.arrayChunk(orders, 10)
        this.$set(this, 'orders', ordersList[0] || [])
        this.$set(this, 'ordersList', ordersList)
        this.$set(this, 'page', 1)
      } else {
        let ordersList = this.arrayChunk(this.ordersClear, 10)
        this.$set(this, 'orders', ordersList[0])
        this.$set(this, 'ordersList', ordersList)
        this.$set(this, 'page', 1)
      }
    },
    getCustomDay(date) {
      const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
      let i = new Date(date).getDay(date)
      return daysOfWeek[i]
    },
    deleteAdminConfirm() {

      // this.sendRequest('DELETE', { id: this.deleteId }, '/removeAdmin', {}, true)
      //   .then(response => {
      //     let findIndex = this.admins.findIndex(a => a.id == this.deleteId)
      //     if (findIndex) {
      //       this.$delete(this.admins, findIndex)
      //     }
      //     this.$set(this, 'deleteId', null)
      //     this.$refs['delete-admin']._confirm()
      //   })
      //   .catch(err => {
      //     this.$store.commit('setError', err)
      //   })
    },
    deleteAdmin(order, ind) {

      this.sendRequest('POST', { ...order, state: 'cancelled' }, '/addBooking', {}, true)
        .then(response => {
          this.$set(this.orders[ind], 'state', 'cancelled')
        })
        .catch(err => {
          this.$store.commit('setError', err)
        })
    },
    editAdmin(admin) {
      this.$set(this, 'newAdmin', { ...admin, type: 'admin' })
      this.showModal('add-admin')
    },
    createAdmin() {
      if (this.newAdmin?.id) {

      } else {
        this.checkPhone(this.newAdmin)
          .then(this.checkName)
          .then(data => {
            this.sendRequest('POST', data, '/addAdmin', {}, true)
              .then(response => {
                let newAdmins = [...this.admins, response]
                this.$set(this, 'admins', newAdmins)
                this.$set(this, 'newAdmin', {
                  name: "",
                  phone: "",
                  user_type: "admin",
                  state: "active"
                })
                this.$refs['add-admin']._confirm()
              })
              .catch(err => {
                this.$store.commit('setError', err)
              })
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
      }
      this.$refs['add-admin']._confirm()
    },
    changePage(state) {
      if (state === 'prev') {
        if (this.page > 1) {
          this.$set(this, 'page', +this.page - 1)
          this.$set(this, 'showListNow', +this.page - 1)
        }
      } else if (state === 'next') {
        // if (this.page < this.countList) {
        this.$set(this, 'page', +this.page + 1)
        this.$set(this, 'showListNow', +this.page + 1)
        // }
      }
      this.changePageData()
    },
    arrayChunk(array, chunkSize) {
      return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    },

    filterRequest() {
      this.sendRequest('GET', this.filter, '/getBookings', {}, true)
        .then(response => {
          response = response.map(o => {
            try {
              o.tour_name = JSON.parse(o.tour_name)[this.language]
              o.city_name = JSON.parse(o.city_name)[this.language]
            } catch (err) { }
            return o
          }).reverse()

          response.sort((a, b) => {
            let dateA = this.$moment(a.tour_date, 'DD.MM.YYYY').unix();
            let dateB = this.$moment(b.tour_date, 'DD.MM.YYYY').unix();
            if (parseInt(dateA) === NaN) {
              dateA = 0
            }
            if (parseInt(dateA) === NaN) {
              dateB = 0
            }
            return dateB - dateA;
          })
          let ordersList = this.arrayChunk(response, 10)

          let countPage = ordersList.length
          this.$set(this, 'orders', ordersList[0])
          this.$set(this, 'ordersList', ordersList)
          this.$set(this, 'ordersClear', response)
        })
        .catch(err => {
          this.$store.commit('setError', err)

        })
    }
  }
}
</script>
<style lang="scss">
#orders-page {
  table {

    th,
    td {
      &.w-85 {
        width: 85px;
      }

      &.name {
        width: 2fr;
      }

      &.date {
        width: 161px;
      }

      &.phone {
        width: 1fr;
      }

      &.w-150 {
        width: 150px;
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .v-input__append-inner {
        height: 40px !important;
        display: flex;
        align-items: center;
        margin-top: 0;
      }
    }
  }

  .table-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #809CB1;

    .count-items {
      display: flex;
      align-items: center;
      margin-right: 48px;

      >span {
        display: block;
        margin-right: 16px;
      }

      .my-select {
        width: 70px;
      }
    }

    .pagination {
      ul {
        display: flex;
        align-items: center;
        border: 1px solid #809CB1;
        border-radius: 4px;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          width: 42px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #809CB1;

          &.active {
            color: #333333;
          }

          &:not(:last-child) {
            border-right: 1px solid #809CB1;
          }
        }
      }

    }
  }

  .edit-btn {
    margin-right: 15px !important;
    padding-right: 15px !important;
    position: relative;

    &:after {
      content: "";
      width: 1px;
      height: 20px;
      background: #D9D9D9;
      position: absolute;
      right: 0px;
      display: block;
    }
  }

  .new-admin-form {
    label {
      display: block;
      margin-bottom: 40px;

      >span {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #6F727A;
        margin-bottom: 8px;
      }
    }

    .row-select {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      label {
        margin-bottom: 0px;
      }
    }
  }

  .button-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ghost {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-align: center !important;
      color: #003A63 !important;
      margin-right: 24px !important;

      &.black {
        color: #000 !important;
      }
    }
  }

  .color-red {
    color: #D10A0F;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
  }

  .del-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #FFDBDB;
    border-radius: 40px;
    margin-right: 16px;
  }

  .notification-success {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #6F727A;
    padding-bottom: 40px;

    .icon {
      background: #249F5D;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .caption {
      margin-top: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      margin-bottom: 16px;
    }
  }

  .datepicker-block {
    position: absolute;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);

    .v-picker {
      .v-btn {
        width: 25px;
        max-width: 25px;
        min-width: 25px;
        height: 28px;
        max-height: 28px;
        min-height: 28px;
        border-radius: 4px;
        letter-spacing: 0;
        border-radius: 4px !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 24px !important;

        &--active {
          background: #003A63;
          border: 1px solid #003A63;
          font-weight: 700 !important;
        }

        &__content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0 !important;
        }
      }

      .v-picker__title {
        display: none !important;
      }
    }

    .v-date-picker-table {
      padding: 0 !important;
    }

    .v-picker__body {
      max-width: 100% !important;
    }

    .v-date-picker-header {
      padding: 4px 0;
    }

    .v-date-picker-table {
      height: auto !important;
    }
  }

  .v-input__append-inner {
    height: 48px !important;
    display: flex;
    align-items: center;
    margin-top: 0;
  }

  .gr {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    align-items: center;
  }
}

.sort {
  display: block;
  width: 100%;
  position: relative;
  cursor: pointer;
  padding-right: 10px;

  &:after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    display: block;
    transform: rotate(45deg);
  }
}
</style>

<template>
  <transition name="fade">
    <div class="modal-backdrop" v-if="open" @click.self="close">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
        :class="{ [size]: true }">
        <div :class="{ [`padding-${padding}`]: true }">
          <Icon v-if="!hiddeCloseBtn" width="34" class="close-icon" height="34" strockeWidth="1.5" :iconName="'x'"
            stroke="#000" @click="close" />
          <header class="modal-header" id="modalTitle" :class="!captinType ? 'no-border' : ''" v-if="!hiddeHeader">
            <div>
              <slot name="header"></slot>
            </div>
            <span>
              <slot name="subheader"></slot>
            </span>
          </header>
          <section class="modal-body" id="modalDescription">
            <slot name="body"></slot>
          </section>
        </div>
        <footer class="modal-footer" :class="{ 'not-border': notFooterBorder }" v-if="!hiddeFooter">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
import Icon from '@/components/Icons.vue';

export default {
  name: "modal",
  components: { Icon },
  props: {
    notFooterBorder: {
      typeof: Boolean,
      default: false
    },
    hiddeHeader: {
      typeof: Boolean,
      default: false
    },
    hiddeFooter: {
      typeof: Boolean,
      default: false
    },
    hiddeCloseBtn: {
      typeof: Boolean,
      default: false
    },
    size: {
      typeof: String,
      default: ''
    },
    padding: {
      typeof: String,
      default: '30-90'
    }
  },
  data() {
    return {
      open: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      captinType: false,
      closeAsCancel: false
    };
  },
  methods: {
    show(opts = {}) {
      if (typeof opts.preload === 'function') {
        opts.preload()
      }
      if (opts.confirmNow === true) {
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
          this.close();
          this.resolvePromise(true);
        });
      }
      this.open = true;
      this.captinType = opts.captin_type || false;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.close();
      this.resolvePromise(false);
    },
    close() {
      this.open = false;
      if (this.closeAsCancel) {
        this.resolvePromise(false);
      }
    },
  },
};
</script>
<style lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);


  .modal {
    background: #ffffff;
    // box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-width: 700px;
    width: 700px;
    color: #1c1c1c;
    max-height: 99%;

    border-radius: 12px;
    position: relative;

    &.padding-30-90 {
      padding: 30px 90px;

      .modal-body {
        padding: 20px 10px;
      }
    }

    &.padding-20 {
      padding: 20px;
    }

    .padding-40 {
      padding: 24px 40px;
    }

    &.padding-70-120 {
      padding: 70px 120px;
    }

    &.padding-57-94 {
      padding: 94px 57px;
    }

    &.small {
      max-width: 393px;
    }

    @media screen and (max-width: 560px) {
      max-width: 95%;
      width: 95%;
    }

    .close-icon {
      position: absolute;
      right: 27px;
      top: 26px;
      cursor: pointer;
      transition: all 0.3s;
      color: #000;
      z-index: 5;

      &:hover {
        color: var(--dl-color-danger-300);
      }
    }

    &.full {
      max-width: 99%;
      max-width: 99%;
      width: 100%;
      height: 100%;
    }

    .modal-footer {
      width: 100%;
      padding: 16px 40px;
      background: #F7F7F9;

      button {
        margin-top: 0;
        border-radius: 5px;
      }
    }

    .modal-header {
      position: relative;
      padding-bottom: 24px;

      div {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        width: 100%;
        color: #333333;
        margin-bottom: 8px;
      }

      span {
        display: block;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .modal-footer {
      border-top: 1px solid #eeeeee;
      justify-content: flex-start;

      &.not-border {
        border: none;
      }

      .btn {
        &:not(:last-child) {
          margin-right: 10px;
        }

        &.reject {
          background: rgba(0, 0, 0, 0.5) !important;
          color: #fff;
        }
      }
    }

    .modal-body {
      position: relative;
      height: 100%;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .btn-close {
      border: none;
      font-size: 20px;
      padding: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #4aae9b;
      background: transparent;
    }

    .btn-green {
      color: white;
      background: #4aae9b;
      border: 1px solid #4aae9b;
      border-radius: 2px;
    }
  }
}
</style>
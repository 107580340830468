<template>
  <div class="my-select-autocomplete" ref="select">
    <div class="label">
      <span @click.self="optionsShow = !optionsShow">
        <template v-if="getSelectValue">
          {{ getSelectValue }}
        </template>
        <template v-else>
          {{ placeholder }}
        </template>
      </span>
      <template v-if="!getSelectValue">
        <button class="ghost" @click="optionsShow = !optionsShow">
          <Icon width="20" height="20" viewBox="0 0 20 20" stroke="#809CB1" strockeWidth="2" :iconName="'selector'" />
        </button>

      </template>
      <template v-else>
        <button class="ghost" @click="clear()">
          <Icon width="20" height="20" viewBox="0 0 20 20" stroke="#809CB1" strockeWidth="2" :iconName="'trash'" />
        </button>
      </template>
    </div>
    <div class="list">
      <vue-slide-up-down class="options" :active="optionsShow == true">
        <ul>
          <li>
            <v-text-field v-model="search" autocomplete="off" outlined class="input" placeholder="Поиск города">
              <template #append>
                <v-icon>mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <div class="search-list">
              <div class="search-list__item" v-for="(city, id) in cityList" :key="`city-${id}`"
                @click="selected(city, id)">
                {{ city }}
              </div>
            </div>
          </li>
        </ul>
      </vue-slide-up-down>
    </div>
  </div>
</template>
<script>
import { globalMethods } from '@/mixins/globalMethods'
import { mapState } from "vuex";

export default {
  name: "SelectAutocomplete",
  props: {
    placeholder: {
      typeof: String,
      default: ''
    },
    value: {
      typeof: String,
      default: ''
    },
    items: {
      typeof: Array,
      default: () => {
        return []
      }
    },
    // model: {
    //     prop: 'value',
    //     event: 'input'
    // }
  },
  data() {
    return {
      search: '',
      searchStart: false,
      optionsShow: false,
      cityList: [],
      cities: {}
    };
  },
  mixins: [globalMethods],
  mounted() {
    this.sendRequest('GET', {}, '/getCities', {}, true)
      .then(response => {
        if (response) {
          const language = this.$store.state.language
          let cities = {}
          response.forEach(item => {
            let data = item?.name || item?.title
            const name = JSON.parse(data)[language]
            cities[item.id] = name
          })
          this.$set(this, 'cities', cities)
        }
      })
      .catch(err => {
        this.$store.commit('setError', err)
      })
    // this.loadGuide('cities', '/getCities')
    //   .then(cities => {
    //     console.log(111111)
    //     console.log(111111)
    //     console.log(111111)
    //     console.log(cities)
    //     this.$set(this, 'cities', cities)
    //   })
  },
  computed: {
    ...mapState({
      language: state => state.language,
    }),
    getSelectValue() {
      // const v = this.items.find(i => i.value == this.value)
      const v = Object.entries(this.cityList).find(([key, city]) => {
        if (key == this.value)
          return true
      })
      if (v) {
        return v[1]
      } else {
        return false
      }
    }
  },
  watch: {
    search() {
      if (!!this.search) {
        this.$set(this, 'searchStart', true)
        let filter = Object.entries(this.cities).reduce((result, [id, nameCity]) => {
          try {            
            if (nameCity?.toLowerCase()?.includes(this.search.toLowerCase())) {
              return { ...result, [id]: nameCity }
            } else {
              return result
            }
          } catch (err) {
            console.error(err)
            return result
          }
        }, {})

        // .filter(([id, name]) => {

        // })
        this.$set(this, 'cityList', filter)
        // this.sendRequest('GET', {}, '/getCities', {}, true)
        //     .then(response => {
        //         this.$set(this, 'goodsList', response)
        //     })
        //     .catch(err => {
        //         this.$store.commit('setError', err)
        //     })
      }
    },
    optionsShow: function (val) {
      const clickOutside = (e) => {
        if (this.handleClickOutside(e, 'select')) {
          this.$set(this, 'optionsShow', false)
        }
      }
      if (val) {
        document.addEventListener('click', clickOutside);
      } else {
        document.removeEventListener('click', clickOutside);
      }
      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
  },
  methods: {
    clear() {
      if (!!this.getSelectValue) {
        this.$emit('input', null)
        this.$set(this, "value", null);
        this.$set(this, "placeholder", 'Выберите город');
      }
    },
    hide() {
      this.$set(this, "optionsShow", false);
    },
    selected: function (city, id) {
      this.$set(this, "value", id);
      this.$set(this, "placeholder", city);
      this.$set(this, "optionsShow", false);
      this.$emit('input', id)
      // this.$emit("selected", item);
    },
  },
};
</script>
<style lang="scss">
.my-select-autocomplete {
  position: relative;

  svg {
    stroke: var(--color-black);
  }

  .label {
    background: #FFFFFF;
    border: 1px solid #809CB1;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6F727A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    // &:after {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: 2;
    // }

    span {
      flex: 1;
      overflow: hidden;
    }

    &.placeholder {
      span {
        opacity: 0.4;
      }
    }
  }

  .list {
    width: 100%;
    position: absolute;
    left: 0%;
    top: 75%;
    z-index: 9;
    padding: 0;
    border-radius: 12px;

    .options {
      >ul {
        transition: all 0.3s;
        position: relative;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 16px 0;
        background: #ffffff;
        //box-shadow: 0px 4px 8px rgba(16, 19, 26, 0.15);
        border: 1px solid #809CB1;
        border-radius: 8px;
        list-style: none;
        max-height: 400px;
        overflow-y: auto;
        // scrollbar-width: none;
        // &::-webkit-scrollbar {
        // display: none;
        // }

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #6F727A;
          transition: all 0.3s;
          cursor: pointer;
          text-align: left;
          padding: 2px 16px;

          //&:hover {
          //  background: rgba(234, 236, 239, 0.5);
          //}

          &.group {
            &:hover {
              background: #fff;
            }

            .caption {
              padding: 10px 5px;
              font-weight: bold;
              font-style: italic;
            }

            ul {
              box-shadow: unset;
              padding: 0;

              li {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .search-list {
    max-height: 250px;
    overflow: auto;

    &__item {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        font-weight: 600;
        background: #F7F7F9;

      }
    }
  }

  .v-input__append-inner {
    height: 48px !important;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
}
</style>

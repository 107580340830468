import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        preloader: false,
        error: null,
        user: null,
        socket: null,
        WSMessage: null,
        windowWidth: window.innerWidth,
        newNotification: false,
        language: 'RU',
        tourType: {
            '599eac533714c4c0bba4d84ed70506a5': 'Историческая',
            'ca25c1c71359964653fbab7ddbbcffda': 'Спортивная',
            '11ff997c057815db124fdda5c7ab30cf': 'Водная',
            '8b35a46c417f45176c240403a0445df9': 'Развлекательная',
        },
        transportTypes: {},
        languagesList: {
            '0c7ebcbcca08de4c2e84665ccb207a16': 'Русский',
            'fc7bbf8d9dee70ce7236ac79be96d987': 'Английский',
        },
        cities: {
            'cd8626195eb5d7cc47387b9325ec2d95': 'Стамбул',
            'efa4b22f384bf4ebb8055603a6ca60fe': 'Анталия',
            'a554cb649af83ddb1c72fab5a7d45990': 'Аланья',
            '1fcb455f632e8594b0bd79282cf33b62': 'Бодрум',
            '10204ef5c335b10c17e191bed29a2d4d4': 'Белек',
            '0204ef5c335b10c17e191bed29a2d4d4': 'Белек',
            'd1518ff8f4ceb1bce91fbd6246e4df29': 'Мармарис',
            'bbd3b7a5168ddae9272e8d41a9e96c65': 'Каппадокия',
            '732f477619cd27212016fd0017087fd2': 'Кемер',
            '405bf2dcf6f117d5a1728a77012e73ac': 'Измир',
            '3ac54c33c429e38b72f7f6d33d4fbb79': 'Фетхие',
            '1ac2e6a0861dc5f392587535916f8803': 'Бурса',
        },
        mediaServerUrl: 'https://admin.excursio.app/api/media/'
    },
    mutations: {
        showPreloader: (state, data) => state.preloader = data,
        setError: (state, data) => state.error = data,
        clearError: (state) => state.error = null,
        setUser: (state, data) => state.user = data,
        setSocket: (state, data) => state.socket = data,
        setWSMessage: (state, data) => state.WSMessage = data,
        setWindowWidth: (state, data) => state.windowWidth = data,
        setNewNotification: (state, data) => state.newNotification = data,
        settourType: (state, data) => state.tourType = data,
        settransportTypes: (state, data) => state.transportTypes = data,
        setlanguagesList: (state, data) => state.languagesList = data,
        setcities: (state, data) => state.cities = data,
        // setUserInterface: (state, data) => state.userInterface = data,
        // unSetUserInterface: (state) => state.userInterface = null,
        // setUserType: (state, data) => state.userType = data,
        // setUserFIO: (state, data) => state.userFIO = data,
        // showPreloader: (state, data) => state.preloader = data,
        // setError: (state, data) => state.error = data,
        // clearError: (state) => state.error = null,
    },
    actions: {},
    modules: {}
})
